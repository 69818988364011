import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ActionMeta,
  CreatableSelect,
  GroupBase,
  MultiValue,
  OnChangeValue,
  OptionBase,
  Select,
} from "chakra-react-select";
import { Field, Form, Formik } from "formik";
import ReactPlayer from "react-player";
import { useYoutubeVideoData } from "../../dal/apis/youtubeApi";
import { Store } from "react-notifications-component";
import { addTagsToVideo, addVideo } from "../../dal/supabase/models/VideoModel";
import { supabase } from "../../supabaseClient";
import { addTags, getAllTags, TagsModelInterface } from "../../dal/supabase/models/TagsModel";

interface TagOption extends OptionBase {
  __isNew__?: boolean;
  value: string;
  label: string;
  id: number;
}

export default function AddVideoForm() {
  const [youtubeVideoLink, setYoutubeVideoLink] = useState("");
  const [tagsOptions, setTagsOptions] = useState<Array<TagOption>>();
  const [selectedOption, setSelectedOption] =
    useState<MultiValue<TagOption>>();
  const youtubeVideoData = useYoutubeVideoData(youtubeVideoLink);
  const cardsBg = useColorModeValue("white", "gray.700");

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  console.log(youtubeVideoData);

  function validateName(value: string) {
    let error;
    if (!value) {
      error = "É necessário adicionar o ID do vídeo";
    }

    setYoutubeVideoLink(value);

    if (!youtubeVideoData.data?.id) {
      error = "ID do vídeo não é valido, por favor inserir outro";
    }
    console.log("teste");
    return error;
  }

  async function addNewVideo(values: any, actions: any, tags: Array<number>) {
    console.log(values);

    const { data, error } = await addVideo({
      title: values.title && values.title,
      description: values.description && values.description,
      youtube_id: values.name,
      user_id: supabase.auth.user()?.id,
    });

    console.log(error);
    if (error) {
      Store.addNotification({
        title: "Erro ao adicionar vídeo!",
        message: `${error.message}. Código ${error.code}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      Store.addNotification({
        title: "Vídeo adicionado!",
        message: "Seu vídeo foi adicionado com sucesso",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });

      if(tags !== undefined) {
        console.log(await addTagsToVideo(data[0].id, tags));
      }
    }

    actions.setSubmitting(false);
  }

  useEffect(() => {
    async function getTags() {
      let tagsList = await getAllTags();

      let tags = tagsList.data.map((tag) => ({
        id: tag.id,
        value: tag.tag,
        label: tag.tag,
      }));

      setTagsOptions(tags);
    }

    getTags();
  }, []);

  return (
    <Formik
      initialValues={{
        name: "Sasuke",
        title: undefined,
        description: undefined,
        tags: undefined,
      }}
      onSubmit={async (values, actions) => {
        /*setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
        }, 1000);*/
        let tagsToAdd: Array<number> = [];

        if(selectedOption?.find(option => option.__isNew__)){
          console.log("adding new tags");
          console.log(selectedOption.filter(option => option.__isNew__));
          const {data, error} = await addTags(selectedOption.filter(option => option.__isNew__).map(e => e.label))

          if(data) {
            console.log(data);
            tagsToAdd.push(...data.filter(e => e.id !== null).map(e => e.id))
            //setSelectedOption(selectedOption.map(e => e.__isNew__ ? data.find(a => a.tag === e.label) : e))
            //setTagsOptions({...tagsOptions, data.map((e) => e as TagOption)})
          }
        }
        /*Store.addNotification({
          title: "Vídeo adicionado!",
          message: "Seu vídeo foi adicionado com sucesso",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });*/
        if(selectedOption !== undefined) {
          tagsToAdd.push(...selectedOption.filter(e => e.id != null).map(e => e.id));
        }
        console.log(tagsToAdd);
        addNewVideo(values, actions, tagsToAdd);
      }}
    >
      {(props) => (
        <Form>
          {
            <Field name="name" validate={validateName}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  py={4}
                  isInvalid={form.errors.name && form.touched.name}
                >
                  <FormLabel htmlFor="name">Link do vídeo</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="https://www.youtube.com/watch?v=" />
                    <Input
                      bg={cardsBg}
                      {...field}
                      id="videoID"
                      placeholder="aqz-KE-bpKQ"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          }
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${youtubeVideoLink}`}
          ></ReactPlayer>
          <Field name="title">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl
                py={4}
                isInvalid={form.errors.title && form.touched.title}
              >
                <FormLabel htmlFor="title">Título do vídeo</FormLabel>
                <Input
                  bg={cardsBg}
                  {...field}
                  id="videoTitle"
                  placeholder="Título do vídeo"
                />
              </FormControl>
            )}
          </Field>
          <Field name="description">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl
                py={4}
                isInvalid={form.errors.description && form.touched.description}
              >
                <FormLabel htmlFor="description">Descrição do vídeo</FormLabel>
                <Textarea
                  bg={cardsBg}
                  {...field}
                  id="videoDescription"
                  placeholder="Insira a descrição do vídeo"
                />
              </FormControl>
            )}
          </Field>
          <Field name="tags">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl py={4}>
                <FormLabel>Tags</FormLabel>
                <CreatableSelect<TagOption, true, GroupBase<TagOption>>
                  isMulti
                  name="tags"
                  options={tagsOptions}
                  placeholder="Escolhas as tags..."
                  onChange={(
                    newValue: MultiValue<TagOption>,
                    actionMeta: ActionMeta<TagOption>
                  ) => {
                    console.group("Value Changed");
                    console.log(newValue);
                    console.log(`action: ${actionMeta.action}`);
                    console.groupEnd();
                    setSelectedOption(newValue);
                    //addTag(newValue.v)
                  }}
                  onInputChange={(inputValue: any, actionMeta: any) => {
                    console.group("Input Changed");
                    console.log(inputValue);
                    console.log(`action: ${actionMeta.action}`);
                    console.groupEnd();
                  }}
                  closeMenuOnSelect={false}
                />
              </FormControl>
            )}
          </Field>
          <Button
            mt={4}
            bg="brand.primary"
            color="white"
            isLoading={props.isSubmitting}
            type="submit"
          >
            Cadastrar Vídeo
          </Button>
        </Form>
      )}
    </Formik>
  );
}
