import React from "react"
import { PageHeading } from "../components/PageHeading"
import { AutoComplete } from "../components/AutoComplete"
import { LinkVideosCard } from "../components/video/LinkVideosCard"
import { Flex } from "@chakra-ui/react"


export const LinkVideos = () => (
    <Flex>
        <Flex
            w="100%"
            flexDir="column"
        >
            <PageHeading title="Linkar Videos" subtitle="Escolha o grupo que você deseja linkar os videos" />
            <Flex>
            <AutoComplete />
            </Flex>
            <Flex flexDir="row" gridGap="20px" flexWrap="wrap">
                <LinkVideosCard weekDay="2a Feira"/>
                <LinkVideosCard weekDay="3a Feira"/>
                <LinkVideosCard weekDay="4a Feira"/>
                <LinkVideosCard weekDay="5a Feira"/>
                <LinkVideosCard weekDay="6a Feira"/>
                <LinkVideosCard weekDay="Sabado"/>
                <LinkVideosCard weekDay="Domingo"/>
            </Flex>
        </Flex>
    </Flex>
)