import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  SimpleGrid,
  useStyleConfig,
  Flex,
  IconButton,
  Stack,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { FiDelete, FiGrid, FiList, FiXCircle } from "react-icons/fi";
import { MdDragHandle } from "react-icons/md"
import { useRouteMatch } from "react-router-dom";
import VideoCard from "../../components/cards/VideoCard";
import { VideoModelInterface } from "../../dal/supabase/models/VideoModel";

interface AddTrainingInterface {
  day: number;
  videosList: Array<VideoModelInterface>;
  onAddClick: () => void;
  onRemoveVideoClick: (videoIndex: number) => void;
  onRemoveDayClick: () => void;
  onChange: (isExpanded: boolean) => void;
  dragHandleProps?: any;
  isExpanded?: boolean;
}

export default function AddTraining(props: AddTrainingInterface) {
  const [filterInput, setFilterInput] = useState("");
  const {day, videosList, onAddClick, onRemoveVideoClick, onRemoveDayClick, dragHandleProps, isExpanded, onChange} = props;
  let match = useRouteMatch();
  const [videoList, setVideoList] = useState(videosList);

  return (
    <Accordion allowToggle sx={useStyleConfig("CustomCard")} defaultIndex={isExpanded ? [0] : undefined} onChange={(val) => onChange(val === 0)}>
      <AccordionItem>
        <h2>
          <Flex flexDir="row">
          <IconButton
            aria-label="Menu"
            {...dragHandleProps}
            h="fill"
            //display={{ base: "inline-flex", md: "none" }}
            icon={<MdDragHandle />}
            size="sm"
            borderRadius={0}
    />
          <AccordionButton
            colorScheme="white" /*_expanded={{ bg: "tomato", color: "white" }}*/
          >
            <Box flex="1" textAlign="left">
              <Flex>
                <Text >Dia {day}</Text>
                <Text color="gray" fontSize="sm" mx="2">{" (" + videoList.length + " vídeos)"}</Text>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          </Flex>
        </h2>
        <AccordionPanel p={0}>
          <Box /*gridGap={8} display="flex" flexDir="row" overflow-y="scroll"*/>
            <SimpleGrid 
              //autoColumns="calc(80% - var(--gutter) * 2)"
              //columns={videos.length + 10} 
              //autoFlow="column"
              //templateColumns="10px"
              //gridGap={8}
              //gridTemplateColumns={`repeat(${videos.length}, calc(25%))`} 
              my={8}
              marginStart={4}
              gridAutoFlow="column" 
              gridGap={8}
              overflow="auto"
              gridAutoColumns={{base: "calc(70%)", sm: "calc(35%)", md: "calc(35%)", lg: "calc(25%)"}} 
            >
              {videoList.map((videoData, index) => (
                <VideoCard 
                  video={videoData} 
                  hoveringChildren={
                    <Box
                      position="absolute"
                      //alignItems="center"
                      display="flex"
                      justifyContent="end"
                      h="full"
                      w="full"
                      top={0}
                      left={0}
                    >
                      <IconButton
                        //bg={"brand.primary"}
                        colorScheme={"brandPrimary"}
                        aria-label="Menu"
                        icon={<FiXCircle />}
                        size="md"
                        onClick={() => {
                          console.log("logging onRemoveClick");
                          onRemoveVideoClick(index);
                        }}
                      />
                    </Box>
                  }
                />
              ))}
            </SimpleGrid>
          </Box>
          <Box m="8">
          <Flex flexDir="row" gridGap="8">
            <Button w="full" onClick={onAddClick}>
              Adicionar Vídeo
            </Button>
            <Button variant='outline' colorScheme="red" onClick={onRemoveDayClick}>
              Remover dia
            </Button>
          </Flex>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
