import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";

import { useUpsert } from "react-supabase";
import { supabase } from "../supabaseClient";
import { UserData, useUserProfile } from "../components/database/User";

export default function SimpleCard() {
  const [userProfile, setUserProfile] = useState<UserData>();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [website, setWebsite] = useState("");
  const [bio, setBio] = useState("");
  const [{ count, data, error, fetching }, execute] = useUpsert("profiles");
  const profile = useUserProfile();

  
  useEffect(() => {
    async function getUser(){
      //let profile = await GetCurrentUser();
      //setUserProfile(profile);
    }
    
    getUser();
  });

  async function onClickSignUp(email: string, username: string, name: string) {
    const { count, data, error } = await execute(
      { user_id: supabase.auth.user()?.id, username: username, name: name }, 
      { onConflict: 'username' },
      (query) => query.eq("user_id", supabase.auth.user()?.id)
    );

    console.log(count);
    console.log(data);
    console.log(error);
  }

  
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Endereço de email</FormLabel>
              <Input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder={supabase.auth.user()?.email}
              />
            </FormControl>
            <FormControl id="username">
              <FormLabel>Username</FormLabel>
              <Input
                type="username"
                onChange={(e) => setUsername(e.target.value)}
                placeholder={"username"}
              />
            </FormControl>
            <FormControl id="name">
              <FormLabel>Nome completo</FormLabel>
              <Input
                type="name"
                onChange={(e) => setName(e.target.value)}
                placeholder={profile.profile?.name ?? ""}
              />
            </FormControl>
            <Button
              bg={"purple.700"}
              color={"white"}
              _hover={{
                bg: "purple.800",
              }}
              onClick={() => onClickSignUp(email, username, name)}
            >
              Completar Cadastro
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

/*
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/dashboard");
  }, [user, loading]);
  return (
    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => signInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Login;*/
