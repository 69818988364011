import { supabase } from "../../../supabaseClient";
import { ProfileModelInterface } from "./ProfileModel";
import { TeamUsersModelInterface } from "./TeamsModel";
import { TrainingModelInterface } from "./TrainingModel";

export interface CompanyTeamsUsersModelInterface extends CompanyModelInterface {
  teams: TeamUsersModelInterface[];
  users: ProfileModelInterface[];
  training?: TrainingModelInterface;
}

export interface CompanyModelInterface extends CompanyInterface {
  id: number /* primary key */;
  created_at: Date;
}

interface CompanyInterface {
  name: string;
  description: string;
  enabled: boolean;
  has_tailored_challenges: boolean;
  user_id: string;
}

export async function addCompany(company: CompanyInterface) {
  return await supabase.from("companies").insert(company, {
    returning: "minimal",
  });
}

export async function updateTrainingFromCompany({companyId, trainingId} : {companyId: number, trainingId: number}) {
  return await supabase.from("companies").update(
    {training_id: trainingId}
  )
  .eq("id", companyId);
}

export async function GetCompaniesFromUser() {
  const { data, error } = await supabase
    .from("companies")
    .select("*")
    .eq("user_id", supabase.auth.user()?.id);

  let companiesList =
    data?.map((video) => video as CompanyModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: companiesList, error };
}

export async function getCompanyById(id: number) {
  const { data, error } = await supabase
    .from("companies")
    .select("*")
    .eq("id", id)
    .eq("user_id", supabase.auth.user()?.id);

  let companiesList =
    data?.map((company) => company as CompanyModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: companiesList, error };
}

export async function getCompanyTeams(id: number) {
  const { data, error } = await supabase
    .from("companies")
    .select(`
      *,
      teams:teams!teams_company_id_fkey(
        *,
        users:profiles(
          *
        )
      ),
      users:profiles(
        *
      ),
      training: training(
        *
      )
    `)
    .eq("id", id)
    .eq("user_id", supabase.auth.user()?.id);

    let companiesTeamsUsersList =
    data?.map((companyTeamsUsers) => companyTeamsUsers as CompanyTeamsUsersModelInterface) || [];

    console.log({data: companiesTeamsUsersList, error: error});

    return { data: companiesTeamsUsersList, error: error};
}
