import { supabase } from "../../../supabaseClient";

export interface TagsFullModelInterface extends TagsModelInterface{
    created_at?: Date;
    created_by: string;
}

export interface TagsModelInterface {
    id: number;
    tag: string;
}

export async function getAllTags() {
    const { data, error } = await supabase
    .from("tags")
    .select("*")
    .limit(100);

    let tagsList = data?.map((tag) => tag as TagsModelInterface) || [];

    console.log("Tag data: ");
    console.log(data);

    return { data: tagsList, error};
}

export async function addTags(tags: Array<string>) {
    return await supabase.from("tags").insert(tags.map(tag => ({"tag": tag})));
}