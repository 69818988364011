import React from "react"

import AddVideoForm from '../components/video/AddVideoForm'
import { PageHeading } from "../components/PageHeading"
import { Flex } from "@chakra-ui/react"


export const Videos = () => (
    <Flex>
        <Flex
            w="full"
            flexDir="column"
        >
            <PageHeading title="Adicionar Video" subtitle="Preencha os dados abaixo para adicionar um vídeo" />
            <Flex flexDir="column" maxW="1024px">
                <AddVideoForm />
            </Flex>
        </Flex>
    </Flex>
)