import React from "react";
import {
  CloseButton,
  Flex,
  BoxProps,
  Text,
  Box,
  useDisclosure,
  Icon,
  Collapse,
} from "@chakra-ui/react";
import { FiBarChart, FiBarChart2, FiHome, FiLink } from "react-icons/fi";

import { MdKeyboardArrowRight, MdVideoLibrary } from "react-icons/md";

import { NavItem } from "./NavItem";
import { FaAngleUp, FaFileExport } from "react-icons/fa";
/*
interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Dashboard", icon: FiHome, path: "/dashboard" },
  { name: "Dashboard Empresa", icon: FiBarChart, path: "/companies" },
  { name: "Dashboard Usuário", icon: FiBarChart2, path: "/dashboard-user" },
  { name: "Biblioteca de Vídeos", icon: MdVideoLibrary, path: "/videos" },
  { name: "Treinos", icon: FaFileExport, path: "/training" },
  { name: "Desafios", icon: FaAngleUp, path: "/challenges" },
  { name: "Link Videos", icon: FiLink, path: "/linkVideos" },
];*/

interface SidebarProps extends BoxProps {
  onClose: () => void;
  //user: UserData;
}

export const SidebarContent = ({
  onClose,
  /*user,*/ ...rest
}: SidebarProps) => {
  let companies = useDisclosure();
  let users = useDisclosure();
  let videos = useDisclosure();
  let training = useDisclosure();
  let challenges = useDisclosure();

  return (
    <Box
      as="nav"
      pos="fixed"
      top={0}
      left={0}
      zIndex="sticky"
      w="60"
      h="full"
      overflowX="hidden"
      overflowY="auto"
      bg="brand.primary"
      borderRightWidth="1px"
      borderColor="blackAlpha.300"
      {...rest}
    >
      <Flex h="100%" flexDir="column" justifyContent="space-between">
        <Flex
          flexDir="column"
          as="nav"
          fontSize="sm"
          color="gray.300"
          aria-label="Main Navigation"
        >
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between"
          >
            <Text
              fontSize="2xl"
              ml="2"
              color="white"
              fontWeight="semibold"
              fontFamily="monospace"
            >
              CATO
            </Text>
            <CloseButton
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
            />
          </Flex>
          <NavItem icon={FiHome} path="/dashboard">
            Dashboard
          </NavItem>
          <NavItem
            icon={FiBarChart}
            //path="/companies"
            onClick={companies.onToggle}
          >
            Empresas
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={companies.isOpen ? "rotate(90deg)" : ""}
            />
          </NavItem>
          <Collapse in={companies.isOpen}>
            <NavItem pl="12" py="2" path="/addCompany">
              Adicionar Empresa
            </NavItem>
            <NavItem pl="12" py="2" path="/companies">
              Dashboard
            </NavItem>
          </Collapse>

          <NavItem
            icon={FiBarChart2} //path="/dashboard-user"
            onClick={users.onToggle}
          >
            Usuários
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={companies.isOpen ? "rotate(90deg)" : ""}
            />
          </NavItem>
          <Collapse in={users.isOpen}>
            <NavItem pl="12" py="2" path="/addUser">
              Adicionar Usuário
            </NavItem>
            <NavItem pl="12" py="2" path="/users">
              Dashboard
            </NavItem>
          </Collapse>

          <NavItem
            icon={MdVideoLibrary} //path="/videos"
            onClick={videos.onToggle}
          >
            Vídeos
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={videos.isOpen ? "rotate(90deg)" : ""}
            />
          </NavItem>
          <Collapse in={videos.isOpen}>
            <NavItem pl="12" py="2" path="/addVideo">
              Adicionar Vídeos
            </NavItem>
            <NavItem pl="12" py="2" path="/videos">
              Biblioteca
            </NavItem>
          </Collapse>

          <NavItem
            icon={FaFileExport} //path="/training"
            onClick={training.onToggle}
          >
            Treinos
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={training.isOpen ? "rotate(90deg)" : ""}
            />
          </NavItem>
          <Collapse in={training.isOpen}>
            <NavItem pl="12" py="2" path="/addTraining">
              Adicionar Treino
            </NavItem>
            <NavItem pl="12" py="2" path="/training">
              Treinos
            </NavItem>
          </Collapse>

          <NavItem
            icon={FaAngleUp} //path="/challenges"
            onClick={challenges.onToggle}
          >
            Desafios
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={challenges.isOpen ? "rotate(90deg)" : ""}
            />
          </NavItem>
          <Collapse in={challenges.isOpen}>
            <NavItem pl="12" py="2" path="/addChallenge">
              Adicionar Desafio
            </NavItem>
            <NavItem pl="12" py="2" path="/challenges">
              Desafios
            </NavItem>
          </Collapse>

          <NavItem icon={FiLink} path="/linkVideos">
            Link Videos
          </NavItem>
        </Flex>
        {/*<ColorModeSwitcher justifySelf="flex-end" />
        <UserDisplay userName={user.name} userType={user.id} />
          */}
      </Flex>
    </Box>
  );
};
