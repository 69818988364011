import {
  useStyleConfig,
  useColorModeValue,
  AspectRatio,
  Box,
  Center,
  Divider,
  Image,
  Text,
  Spinner,
  Badge,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useYoutubeVideoData } from "../../dal/apis/youtubeApi";
import { VideoModelInterface } from "../../dal/supabase/models/VideoModel";

export default function VideoCard({
  video,
  props,
  hoveringChildren,
}: {
  video: VideoModelInterface;
  props?: any;
  hoveringChildren?: React.ReactNode;
}) {
  const [hoveringMouse, setHoveringMouse] = useState(false);
  const styles = useStyleConfig("CustomCard");

  const youtubeVideoData = useYoutubeVideoData(video.youtube_id);

  console.log(video);

  return (
    <Box
      sx={styles}
      {...props}
      overflow="hidden"
      position="relative"
      onMouseEnter={() => setHoveringMouse(true)}
      onMouseLeave={() => setHoveringMouse(false)}
      //minW="160"
      //maxW="320"
      boxShadow="base"
      rounded="lg"
      bg={useColorModeValue("white", "gray.700")}
      borderRadius="lg"
      border="1px"
      borderColor={useColorModeValue("gray.100", "gray.600")}
    >
      {youtubeVideoData.isLoading ? (
        <Center>
          <Spinner
            size="xl"
            color="brand.primary"
            thickness="4px"
            emptyColor="gray.200"
            speed="0.65s"
          />
        </Center>
      ) : (
        <>
          <Center>
            <AspectRatio w="full" ratio={16 / 9}>
              <Image
                src={youtubeVideoData.data?.snippet?.mqThumbnail}
                alt="Video do youtube"
                objectFit="scale-down"
              />
            </AspectRatio>
          </Center>
          <Divider />
          <Box p={4}>
            <Text as="h4" fontSize="sm" mb={2}>
              {youtubeVideoData.data?.snippet?.title}
            </Text>
            {video.tags?.map((tag, index) =>
              <Badge key={"tag-" + index} m={1} py={1} px={2}>{tag.tag?.tag}</Badge>
            )}
          </Box>
          {hoveringMouse && hoveringChildren}
        </>
      )}
    </Box>
  );
}

/*export function VideoSideCard({
  video,
}: {
  video: VideoModelInterface;
  props?: any;
}) {
  const [hoveringMouse, setHoveringMouse] = useState(false);

  const youtubeVideoData = useYoutubeVideoData(video.youtube_id);

  return (
    <Center py={6}>
      <Stack
        borderWidth="1px"
        borderRadius="lg"
        w="full"
        height="20rem"
        direction="row"
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        padding={4}
      >
        <Flex flex={1} bg="blue.200">
          <AspectRatio
            w="320"
            h="180"
            //w="100" h="full"
            ratio={16 / 9}
          >
            <Image
              w="320"
              h="180"
              src={youtubeVideoData.data?.snippet?.mqThumbnail}
              alt="Video do youtube"
              objectFit="scale-down"
            />
          </AspectRatio>
        </Flex>
        <Stack
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={1}
          pt={2}
        >
          <Heading fontSize={"2xl"} fontFamily={"body"}>
            Lindsey James
          </Heading>
          <Text fontWeight={600} color={"gray.500"} size="sm" mb={4}>
            @lindsey_jam3s
          </Text>
          <Text
            textAlign={"center"}
            color={useColorModeValue("gray.700", "gray.400")}
            px={3}
          >
            Actress, musician, songwriter
          </Text>
        </Stack>
      </Stack>
    </Center>
  );
}*/
