import { Box, useColorModeValue, Table, TableCaption, Thead, Tr, Tbody, Th, Td, Tfoot, Divider, Heading } from "@chakra-ui/react";
import React from "react";

export default function TableCard({ title }: { title: string }) {
    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="base"
            bg={useColorModeValue('white', 'gray.700')}
        >
            <Heading p={6} as="h6" fontSize="md" color="gray.500" textAlign="left">{title}</Heading>
            <Divider />
            <Box p={6}>
                <Table variant="simple">
                    <TableCaption placement="top">Lista de empresas e grupos</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Empresa</Th>
                            <Th>Grupo</Th>
                            <Th isNumeric>Quantidade de Pessoas</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>SmartCATO</Td>
                            <Td>Comercial</Td>
                            <Td isNumeric>4</Td>
                        </Tr>
                        <Tr>
                            <Td>SmartCATO</Td>
                            <Td>Marketing</Td>
                            <Td isNumeric>2</Td>
                        </Tr>
                        <Tr>
                            <Td>SmartCATO</Td>
                            <Td>Engenharia</Td>
                            <Td isNumeric>3</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </Box>
    )
}