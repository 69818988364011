import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spacer,
  useStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import VideoCard from "../components/cards/VideoCard";
import { PageHeading } from "../components/PageHeading";
import { GetVideosFromUser, VideoModelInterface } from "../dal/supabase/models/VideoModel";

export default function VideosLibrary() {
  const [filterInput, setFilterInput] = useState("");
  const [videosList, setVideosList] = useState<VideoModelInterface[]>([]);
  const cardStyles = useStyleConfig("CustomCard");

  console.log("Videos data:")
  console.log(videosList)
  /*console.log(count);
  console.log(data)
  console.log(error)
  console.log(fetching)*/
  
  //let match = useRouteMatch();

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  useEffect(() => {
    async function getAllVideos(){
      let videosAll = await GetVideosFromUser();
      setVideosList(videosAll.data);
    }
    
    getAllVideos();
  }, [])
  return (
    <Flex>
      <Flex w="full" flexDir="column">
        <Flex flexDir="row">
          <PageHeading
            title="Biblioteca de Vídeos"
            subtitle="Veja sua biblioteca de vídeos ou adicione um novo"
          />
          <Spacer />
          <Button
            as={NavLink}
            to={`/addVideo`}
            aria-label="refresh"
            mr="4"
            fontSize="md"
            bg="brand.primary"
            color="white"
            _hover={{
              bg: "purple.400",
              color: "white",
            }}
            onClick={() => console.log("Adicionar vídeo")}
          >
            Adicionar Vídeo
          </Button>
        </Flex>
        <InputGroup mb="20px">
          <InputLeftElement color="gray.500" children={<FiSearch />} />
          <Input
            //p={4}
            sx={cardStyles}
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Pesquise o vídeo pelo nome..."}
          />
        </InputGroup>
        <SimpleGrid
          gap="20px"
          columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
        >
          {videosList.map((videoData, index) => (
            <VideoCard key={index} video={videoData} />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}
