import React, { useState } from 'react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'

export interface Item {
    label: string;
    value: string;
}

const groups = [
    { value: "SmartCATO", label: "SmartCATO" },
    { value: "FB Pneus", label: "FB Pneus" },
    { value: "2pra1", label: "2pra1" },
    { value: "IBM", label: "IBM" }
];

export const AutoComplete = () => {
    const [pickerItems, setPickerItems] = useState<Item[]>(groups);
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);

    const handleCreateItem = (item: Item) => {
        setPickerItems((curr) => [...curr, item]);
        setSelectedItems((curr) => [...curr, item]);
    };

    const handleSelectItemsChange = (selectedItems?: Item[]) => {
        if (selectedItems) {
            setSelectedItems(selectedItems);
        }
    };

    return (
        <CUIAutoComplete
            label="Escolha os grupos para adicionar os vídeos"
            placeholder="Digite o nome do grupo"
            onCreateItem={handleCreateItem}
            items={pickerItems}
            selectedItems={selectedItems}
            onSelectedItemsChange={(changes) => handleSelectItemsChange(changes.selectedItems)}
            /*listStyleProps={{bg:useColorModeValue("white","gray.900")}}
            listItemStyleProps={{bg:useColorModeValue("gray.900","gray.100"), _hover:{bg:useColorModeValue("white","gray.700")}}}*/
        />
    )

}