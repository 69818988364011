import { supabase } from "../../../supabaseClient";

export interface RankingModelInterface {
    id: number;
    user: string;
    company: number;
    team: number;
    score: number;
    name: string;
}

export async function getRankingFromCompanyById(id: number) {
    const { data, error } = await supabase
      .from("leaderboard")
      .select("*")
      .eq("company", id)
      .order("score", { ascending: false })
      .limit(100);
  
    let rankingList =
      data?.map((company) => company as RankingModelInterface) || [];
    console.log("Data:");
    console.log(data);
    return { data: rankingList, error };
  }