import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useStyleConfig,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { addCompany } from "../../dal/supabase/models/CompaniesModel";
import { Store } from "react-notifications-component";
import { supabase } from "../../supabaseClient";

export default function AddCompany() {
  const [filterInput, setFilterInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const cardsStyle = useStyleConfig("CustomCard");

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  // Update the state when input changes
  const handleDescriptionChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setDescriptionInput(value);
  };

  async function addNewCompany(values: any, actions: any) {
    console.log(values);

    if (supabase.auth.user() === null) {
      Store.addNotification({
        title: "Erro ao adicionar empresa!",
        message: `Não logado. Favor logar`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      actions.setSubmitting(false);

      return;
    }

    const { error } = await addCompany({
      name: values.title && values.title,
      description: values.description && values.description,
      user_id: supabase.auth.user()?.id || "",
      enabled: true,
      has_tailored_challenges: false,
    });

    console.log(error);
    if (error) {
      Store.addNotification({
        title: "Erro ao adicionar a empresa!",
        message: `${error.message}. Código ${error.code}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      Store.addNotification({
        title: "Empresa adicionado!",
        message: "A empresa foi adicionado com sucesso",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }

    actions.setSubmitting(false);
  }

  useEffect(() => {
    async function getAllUsersFromDoctor() {
      //let videosAll = await GetVideosFromUser();
      //setVideosList(videosAll.data);
    }

    getAllUsersFromDoctor();
  }, []);

  return (
    <Stack spacing={3}>
      {/*<VideoPickerDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        videosList={videosList}
        onSelect={(video) => {
          setExercise(video);
          onClose();
        }}
    />*/}
      <Formik
        initialValues={{
          title: undefined,
          description: undefined,
          video: undefined,
        }}
        onSubmit={(values, actions) => {
          addNewCompany(values, actions);
        }}
      >
        {(props) => (
          <Form>
            <Field name="title">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isRequired
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel htmlFor="title">Nome da Empresa</FormLabel>
                  <Input
                    sx={cardsStyle}
                    {...field}
                    id="companyTitle"
                    placeholder="Nome da empresa"
                  />
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isRequired
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel htmlFor="description">
                    Descrição da Empresa
                  </FormLabel>
                  <Textarea
                    sx={cardsStyle}
                    {...field}
                    id="challengeDescription"
                    placeholder="Insira a descrição da empresa..."
                  />
                </FormControl>
              )}
            </Field>
            <Flex mt={4} gridGap={4} justifyContent="flex-end">
              <Button as={NavLink} to="companies">
                Cancelar
              </Button>
              <Button
                bg="brand.primary"
                color="white"
                isLoading={props.isSubmitting}
                type="submit"
              >
                Cadastrar Empresa
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
