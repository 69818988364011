import { supabase } from "../../../supabaseClient";

export interface ProfileModelInterface extends ProfileInterface {
  id: number /* primary key */;
  joined_at: Date;
  company?: {
    name: string;
  };
  team?: {
    name: string;
  };
  total_points?: {
    challenge: number;
    exercise: number;
    posture: number;
  };
}

interface ProfileInterface {
  username: string;
  name: string;
  website: string;
  bio: string;
  avatar_url: string;
  user_id: string;
  company_id: number;
  team_id: number;
}

export async function addProfile(profile: ProfileModelInterface) {
  return await supabase.from("profiles").upsert(profile, {
    returning: "minimal",
  });
}

export async function GetProfileFromUser() {
  const { data, error } = await supabase
    .from("profiles")
    .select("*")
    .eq("user_id", supabase.auth.user()?.id);

  let profileList =
    data?.map((profile) => profile as ProfileModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: profileList, error: error };
}

export async function getProfileByUserUUID(uuid: string) {
  const { data, error } = await supabase
    .from("profiles")
    .select("*")
    .eq("user_id", uuid);

  let profileList =
    data?.map((profile) => profile as ProfileModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: profileList, error };
}

export async function getProfileById(id: number) {
  const { data, error } = await supabase
    .from("profiles")
    .select(
      `
      *,
      company:companies(name),
      team:teams(name)
      `
    )
    .eq("id", id);

  let profileList =
    data?.map((profile) => profile as ProfileModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: profileList, error };
}

export async function getProfilesConnectedToCurrentUser() {
  const { data, error } = await supabase
    .from("profiles")
    .select(
      `
      *,
      company:companies(name),
      team:teams(name)
      `
    )
    .eq("doctor_id", supabase.auth.user()?.id);

  let profileList =
    data?.map((profile) => profile as ProfileModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: profileList, error };
}
