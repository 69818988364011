import {
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spacer,
  useStyleConfig,
  Text,
  Box,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import SimpleCard from "../../components/cards/SimpleCard";
import { PageHeading } from "../../components/PageHeading";
import {
  CompanyModelInterface,
  GetCompaniesFromUser,
} from "../../dal/supabase/models/CompaniesModel";

export default function Companies() {
  const [filterInput, setFilterInput] = useState("");
  const [companiesList, setCompaniesList] = useState<
    Array<CompanyModelInterface>
  >([]);
  const cardStyles = useStyleConfig("CustomCard");
  const overlayBoxBgColor = useColorModeValue(
    "blackAlpha.500",
    "blackAlpha.700"
  );
  
  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  useEffect(() => {
    async function getAllCompanies() {
      let companiesAll = await GetCompaniesFromUser();
      setCompaniesList(companiesAll.data);
    }

    getAllCompanies();
  }, []);

  console.log();
  return (
    <Flex>
      <Flex w="full" flexDir="column">
        <Flex flexDir="row">
          <PageHeading
            title="Empresas"
            subtitle="Procure ou crie uma empresa para adicionar na plataforma"
          />
          <Spacer />
          <Button
            as={NavLink}
            to={`/addCompany`}
            aria-label="refresh"
            mr="4"
            fontSize="md"
            bg="brand.primary"
            color="white"
            _hover={{
              bg: "purple.400",
              color: "white",
            }}
            onClick={() => console.log("Creating company")}
          >
            Criar uma Empresa
          </Button>
        </Flex>
        <InputGroup mb="20px">
          <InputLeftElement color="gray.500" children={<FiSearch />} />
          <Input
            //p={4}
            sx={cardStyles}
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Pesquise a empresa pelo nome..."}
          />
        </InputGroup>
        <Flex flexDir="column" gridGap={8}>
          <Heading textAlign="left" as="h3" size="lg" noOfLines={1}>
            Lista de Empresas
          </Heading>
          <SimpleGrid
            gap="20px"
            columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
          >
            {companiesList
              .filter(
                (company) =>
                  filterInput === undefined ||
                  company.name
                    .toLowerCase()
                    .search(filterInput.toLowerCase()) >= 0 ||
                  company.description
                    .toLowerCase()
                    .search(filterInput.toLowerCase()) >= 0
              )
              .map((company, index) => (
                <SimpleCard
                  key={index}
                  children={
                    <>
                      <Center>
                        <Text p={8}>{company.name}</Text>
                      </Center>
                      <Box p={4}>
                        <Text as="h4" fontSize="sm" noOfLines={3}>
                          {company.description}
                        </Text>
                      </Box>
                    </>
                  }
                  hoveringChildren={
                    <Box
                      backgroundColor={overlayBoxBgColor}
                      position="absolute"
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                      h="full"
                      w="full"
                      top={0}
                      left={0}
                    >
                      <Button
                        as={NavLink}
                        bg="brand.primary"
                        color="white"
                        p={8}
                        to={`/companies/${company.id}`}
                        _hover={{
                          bg: "gray.500",
                          color: "white",
                        }}
                      >
                        Selecionar
                      </Button>
                    </Box>
                  }
                />
              ))}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}
