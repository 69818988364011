import { useStyleConfig, useColorModeValue, Box } from "@chakra-ui/react";
import React, { useState } from "react";

export default function SimpleCard({
  children,
  props,
  hoveringChildren,
}: {
  children: React.ReactNode;
  props?: any;
  hoveringChildren?: React.ReactNode;
}) {
  const [hoveringMouse, setHoveringMouse] = useState(false);
  const styles = useStyleConfig("CustomCard");

  //const youtubeVideoData = useYoutubeVideoData(video.youtube_id);

  return (
    <Box
      sx={styles}
      {...props}
      overflow="hidden"
      position="relative"
      onMouseEnter={() => setHoveringMouse(true)}
      onMouseLeave={() => setHoveringMouse(false)}
      //minW="160"
      //maxW="320"
      boxShadow="base"
      rounded="lg"
      bg={useColorModeValue("white", "gray.700")}
      borderRadius="lg"
      border="1px"
      borderColor={useColorModeValue("gray.100", "gray.600")}
    >
      {children}
      {hoveringMouse && hoveringChildren}
    </Box>
  );
}
