import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { NavLink, useRouteMatch } from "react-router-dom";
import VideoCard from "../../components/cards/VideoCard";
import { addChallenge } from "../../dal/supabase/models/Challenges";
import { Store } from "react-notifications-component";
import {
  GetVideosFromUser,
  VideoModelInterface,
} from "../../dal/supabase/models/VideoModel";
import { supabase } from "../../supabaseClient";
import VideoPickerDrawer from "../../components/VideoPickerDrawer";

export default function AddChallenge() {
  const [filterInput, setFilterInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [exercise, setExercise] = useState<VideoModelInterface>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cardsStyle = useStyleConfig("CustomCard");
  let match = useRouteMatch();
  const [videosList, setVideosList] = useState<VideoModelInterface[]>([]);

  const overlayBoxBgColor = useColorModeValue(
    "blackAlpha.500",
    "blackAlpha.700"
  );

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  // Update the state when input changes
  const handleDescriptionChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setDescriptionInput(value);
  };

  async function addNewChallenge(values: any, actions: any) {
    console.log(values);

    if(supabase.auth.user() === null || exercise === undefined) {
      Store.addNotification({
        title: "Erro ao adicionar vídeo!",
        message: supabase.auth.user() === null ? `Não logado. Favor logar` : `Exercício não selecionado`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      actions.setSubmitting(false);

      return;
    }

    const { error } = await addChallenge({
      title: values.title && values.title,
      description: values.description && values.description,
      user_id: supabase.auth.user()?.id || "",
      video_id: exercise.id,
    });

    console.log(error);
    if (error) {
      Store.addNotification({
        title: "Erro ao adicionar vídeo!",
        message: `${error.message}. Código ${error.code}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      Store.addNotification({
        title: "Vídeo adicionado!",
        message: "Seu vídeo foi adicionado com sucesso",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }

    actions.setSubmitting(false);
  }

  useEffect(() => {
    async function getAllVideos() {
      let videosAll = await GetVideosFromUser();
      setVideosList(videosAll.data);
    }

    getAllVideos();
  }, []);

  return (
    <Stack spacing={3}>
      <VideoPickerDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        videosList={videosList}
        onSelect={(video) => {
          setExercise(video);
          onClose();
        }}
      />
      <Formik
        initialValues={{
          title: undefined,
          description: undefined,
          video: undefined,
        }}
        onSubmit={(values, actions) => {
          /*setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
          }, 1000);*/
          /*Store.addNotification({
          title: "Vídeo adicionado!",
          message: "Seu vídeo foi adicionado com sucesso",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });*/
          //addNewVideo(values, actions);
          addNewChallenge(values, actions);
        }}
      >
        {(props) => (
          <Form>
            <Field name="title">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isRequired
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel htmlFor="title">Título do desafio</FormLabel>
                  <Input
                    sx={cardsStyle}
                    {...field}
                    id="challengeTitle"
                    placeholder="Título do desafio"
                  />
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isRequired
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel htmlFor="description">
                    Descrição do desafio
                  </FormLabel>
                  <Textarea
                    sx={cardsStyle}
                    {...field}
                    id="challengeDescription"
                    placeholder="Insira a descrição do desafio"
                  />
                </FormControl>
              )}
            </Field>
            <Field name="video">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isRequired
                  isInvalid={form.errors.video && form.touched.video}
                >
                  <FormLabel htmlFor="description">Video do desafio</FormLabel>
                  {
                    //form.values.video && <VideoCard video={form.values.video} />
                  }
                  {exercise && (
                    <VideoCard props={{ maxW: 300 }} video={exercise} />
                  )}
                  <Button
                    mt={4}
                    leftIcon={<MdAdd />}
                    //colorScheme="gray"
                    onClick={onOpen}
                  >
                    {exercise ? "Trocar Vídeo" : "Selecionar Vídeo"}
                  </Button>
                </FormControl>
              )}
            </Field>
            <Flex mt={4} gridGap={4} justifyContent="flex-end">
              <Button as={NavLink} to="challenges">Cancelar</Button>
              <Button
                bg="brand.primary"
                color="white"
                isLoading={props.isSubmitting}
                type="submit"
              >
                Cadastrar Desafio
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
