import { useEffect, useState } from "react";
import youtube from "./youtube";

interface YoutubeSnippet {
  publishedAt: string;
  channelId: string;
  title: string;
  description: string;
  mqThumbnail: string;
  channelTitle: string;
  tags: Array<string>;
}

interface YoutubeContentDetails {
  duration: string;
  dimension: string;
  definition: string;
  caption: string;
  licensedContent: string;
}

export interface YoutubeVideoInterface {
  kind?: string;
  etag?: string;
  id?: string;
  snippet?: YoutubeSnippet;
  contentDetails?: YoutubeContentDetails;
}

export const convertYoutubeAPIToYoutubeVideo = (data: any) => {
  console.log(data);
  return {
    kind: data.kind,
    etag: data.etag,
    id: data.id,
    snippet: {
      mqThumbnail: data.snippet.thumbnails.medium.url,
      ...data.snippet,
    },
    contentDetails: {
      ...data.contentDetails,
    },
  } as YoutubeVideoInterface;
};

export const useYoutubeVideoData = (youtube_id: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState<YoutubeVideoInterface>();


  async function getYoutubeData() {
    // getting youtube data
    console.log("Getting youtube data");
    setIsLoading(true);
    console.log("Getting data");

    const response = await youtube
      .get("/videos", {
        params: {
          id: youtube_id,
        },
      })
      .then(async (response: { data: any }) => {
          console.log("response: ")
          console.log(response)
        if (response.data.items.length === 0) {
          setData({});
        } else {
          setData(convertYoutubeAPIToYoutubeVideo(response.data.items[0]));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // catch and return the error
        setError(error); //new UserInputError(error);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getYoutubeData();
  }, [youtube_id]);

  return { isLoading, error, data };
};
