
import { Box, Grid, useColorModeValue, Table, TableCaption, Thead, Tr, Tbody, Th, Td, Select, GridItem, Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { FiFile, FiHome, FiTerminal } from "react-icons/fi";
import InfoCard, { InfoCardProps } from "../components/dashboard/InfoCard";
import PostureChart from "../components/dashboard/PostureChart";

export interface CompanyProps {
  company: string;
  stretching: string;
  challenges: string;
  level: string;
  teams: Array<CompanyTeamProps>;
}

export interface CompanyTeamProps {
  team: string;
  users: Array<UserProps>;
}

export interface UserProps {
  name: string;
  posture: number;
  points: number;
  challenges: number;
  stretching: number;
  level: number;
}

const companyData: CompanyProps =
{
  company: "Intelbras",
  stretching: "40%",
  challenges: "30%",
  level: "6",
  teams: [
    {
      team: "Marketing",
      users: [
        {
          name: "Guilherme Damasio",
          posture: 70,
          points: 712,
          challenges: 40,
          stretching: 30,
          level: 8
        },
        {
          name: "Alexandre Turozi",
          posture: 72,
          points: 687,
          challenges: 48,
          stretching: 45,
          level: 7
        },
        {
          name: "Gemma Coulson",
          posture: 64,
          points: 680,
          challenges: 20,
          stretching: 60,
          level: 6
        },
        {
          name: "Mimi Worthington",
          posture: 80,
          points: 542,
          challenges: 80,
          stretching: 60,
          level: 7
        },
        {
          name: "Alisha Melton",
          posture: 40,
          points: 631,
          challenges: 52,
          stretching: 28,
          level: 8
        },
      ]
    },
    {
      team: "Engenharia",
      users: [
        {
          name: "Habib Parsons",
          posture: 54,
          points: 831,
          challenges: 44,
          stretching: 32,
          level: 5
        },
        {
          name: "Junaid Copeland",
          posture: 71,
          points: 452,
          challenges: 65,
          stretching: 53,
          level: 4
        },
        {
          name: "Habib Parsons",
          posture: 92,
          points: 926,
          challenges: 79,
          stretching: 92,
          level: 5
        }
      ]
    }
  ]
}

const TeamQty = companyData.teams.length;
const UsersQty = companyData.teams.map(team => team.users.length).reduce((partial_sum, val) => partial_sum + val, 0);
const companyStretchAvg = companyData.teams.map(team => team.users.map(e => e.stretching).reduce((partial_sum, val) => partial_sum + val, 0)).reduce((partial_sum, val) => partial_sum + val, 0) / UsersQty;
const companyChallengesAvg = companyData.teams.map(team => team.users.map(e => e.challenges).reduce((partial_sum, val) => partial_sum + val, 0)).reduce((partial_sum, val) => partial_sum + val, 0) / UsersQty;
const companyLevelAvg = companyData.teams.map(team => team.users.map(e => e.level).reduce((partial_sum, val) => partial_sum + val, 0)).reduce((partial_sum, val) => partial_sum + val, 0) / UsersQty;
const user = companyData.teams[0].users[0];

const dataListInfoCards: Array<InfoCardProps> = [
  {
    title: "Empresa",
    subtitle: `${companyData.company}`,
    icon: FiHome,
    iconBg: "gray.500"
  },
  {
    title: "Equipe",
    subtitle: `${companyData.teams[0].team}`,
    icon: FiHome,
    iconBg: "gray.500"
  },
  {
    title: "Alongamentos",
    subtitle: `${user.stretching}%`,
    icon: FiFile,
    iconBg: "gray.500"
  },
  {
    title: "Desafios",
    subtitle: `${user.challenges}%`,
    icon: FiTerminal,
    iconBg: "green.400"
  },
  {
    title: "Nivel",
    subtitle: `${user.level}`,
    icon: FiHome,
    iconBg: "gray.400"
  },
  {
    title: "Pontos",
    subtitle: `${user.points} pontos`,
    icon: FiHome,
    iconBg: "gray.400"
  }
]

export const DashboardUser = () => (
  <Flex textAlign="center" fontSize="xl" flexDirection="column">
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(8, 1fr)"
      gridGap="40px"
    >
      <GridItem colSpan={{ sm: 8, lg: 4, "2xl": 4 }}>
        <Heading
          as="h2"
          textAlign="left"
        >
          {user.name}
        </Heading>
      </GridItem>
      <GridItem colSpan={{ sm: 8, lg: 3, "2xl": 3 }} colStart={{ sm: 1, lg: 6, "2xl": 6 }}>
        <Select placeholder="Escolha uma opção">
          <option value="30">30 dias</option>
          <option value="60">60 dias</option>
          <option value="90">90 dias</option>
        </Select>
      </GridItem>

      {
        dataListInfoCards.map(function (data) {
          return (
            <GridItem colSpan={{ sm: 8, lg: 4, "2xl": 2 }}>
              <InfoCard props={data} />
            </GridItem>
          )
        })
      }

      <GridItem colSpan={{ sm: 8, lg: 8, "2xl": 3 }}>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="base"
          bg={useColorModeValue('white', 'gray.700')}
        >
          <Heading p={6} as="h6" fontSize="md" color="gray.500" textAlign="left">Equipes</Heading>
          <Divider />
          <Box p={6}>
            <Table variant="simple">
              <TableCaption placement="top">Lista de pessoas por equipe</TableCaption>
              <Thead>
                <Tr>
                  <Th>Equipe</Th>
                  <Th isNumeric>Quantidade de Pessoas</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  companyData.teams.map(function (data) {
                    return (
                      <Tr>
                        <Td>{data.team}</Td>
                        <Td isNumeric>{data.users.length}</Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
            </Table>
          </Box>
        </Box>
      </GridItem>
      <GridItem colSpan={{ sm: 8, lg: 8, "2xl": 5 }}>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="base"
          bg={useColorModeValue('white', 'gray.700')}
        >
          <Heading p={6} as="h6" fontSize="md" color="gray.500" textAlign="left">Gráfico da postura</Heading>
          <Divider />
          <Box maxH="350px" p={6}>
            <PostureChart />
          </Box>
        </Box>
      </GridItem>
      <GridItem colSpan={8}>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="base"
          bg={useColorModeValue('white', 'gray.700')}
        >
          <Heading p={6} as="h6" fontSize="md" color="gray.500" textAlign="left">Ranking</Heading>
          <Divider />
          <Box p={6}>
            <Table variant="simple">
              <TableCaption placement="top">Ranking da Empresa</TableCaption>
              <Thead>
                <Tr>
                  <Th>Pessoa</Th>
                  <Th>Equipe</Th>
                  <Th>Postura</Th>
                  <Th isNumeric>Pontos</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  companyData.teams.map(team => team.users.map(user => {
                    return (
                      <Tr>
                        <Td>{user.name}</Td>
                        <Td>Teste</Td>
                        <Td>{user.posture}%</Td>
                        <Td isNumeric>{user.points}</Td>
                      </Tr>
                    );
                  }))
                }
              </Tbody>
            </Table>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  </Flex>
)