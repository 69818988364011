import {
  useStyleConfig,
  useColorModeValue,
  Box,
  Center,
  Divider,
  Text,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TrainingModelInterface } from "../../dal/supabase/models/TrainingModel";

export default function TrainingCard({
  training,
  props,
  hoveringChildren,
}: {
  training: TrainingModelInterface;
  props?: any;
  hoveringChildren?: React.ReactNode;
}) {
  const [hoveringMouse, setHoveringMouse] = useState(false);
  const styles = useStyleConfig("CustomCard");

  //const youtubeVideoData = useYoutubeVideoData(video.youtube_id);

  return (
    <Box
      sx={styles}
      {...props}
      overflow="hidden"
      position="relative"
      onMouseEnter={() => setHoveringMouse(true)}
      onMouseLeave={() => setHoveringMouse(false)}
      //minW="160"
      //maxW="320"
      boxShadow="base"
      rounded="lg"
      bg={useColorModeValue("white", "gray.700")}
      borderRadius="lg"
      border="1px"
      borderColor={useColorModeValue("gray.100", "gray.600")}
    >
      {false ? (
        <Center>
          <Spinner
            size="xl"
            color="brand.primary"
            thickness="4px"
            emptyColor="gray.200"
            speed="0.65s"
          />
        </Center>
      ) : (
        <>
          <Center>
            <Text p={8}>{training.title}</Text>
          </Center>
          <Divider />
          <Box p={4}>
            <Text as="h4" fontSize="sm">
              Dias: {training.days}
            </Text>
            <Text as="h4" fontSize="sm">
              Criado em: {new Date(training.created_at).toLocaleString("pt-br")}
            </Text>
          </Box>
          {hoveringMouse && hoveringChildren}
        </>
      )}
    </Box>
  );
}
