import { Avatar, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SimpleCard from "../../components/cards/SimpleCard";
import {
  getProfileById,
  ProfileModelInterface,
} from "../../dal/supabase/models/ProfileModel";

const ProfileMainDetails = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <Flex flexDir="column">
      <Text color="gray">{title.toUpperCase()}</Text>
      <Text>{subtitle}</Text>
    </Flex>
  );
};

const ProfileLineDetails = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <Flex flexDir="row" gridGap={2}>
      <Text color="gray">{title.toUpperCase()}:</Text>
      <Text>{subtitle}</Text>
    </Flex>
  );
};

export default function Profile({ match }: { match: any }) {
  const [userProfile, setUserProfile] = useState<ProfileModelInterface>();

  useEffect(() => {
    async function getUserProfile() {
      let profile = await getProfileById(match.params.userId);

      setUserProfile(profile.data[0]);
    }

    getUserProfile();
  }, []);

  return (
    <Flex>
      <SimpleCard props={{ w: "full", p: 8 }}>
        <Flex w="full" flexDir="column" gridGap={4}>
          <Flex flexDir="row" gridGap={8}>
            <Avatar
              src={userProfile?.avatar_url}
              size="xl"
              name={userProfile?.name}
            />
            <Flex flexDir="column" gridGap={4}>
              <Heading as="h3" size="lg">
                {userProfile?.name}
              </Heading>
              <Flex flexDir="row" fontSize="sm" gridGap={4}>
                <ProfileMainDetails title="email" subtitle="email@email.com" />
                <Divider orientation="vertical" />
                <ProfileMainDetails
                  title="telefone"
                  subtitle="+xx (xx) xxx-xxx-xxx"
                />
                <Divider orientation="vertical" />
                <ProfileMainDetails
                  title="username"
                  subtitle={userProfile?.username ?? "-"}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir="column" fontSize="sm">
            <ProfileLineDetails
              title="Empresa"
              subtitle={userProfile?.company?.name ?? "-"}
            />
            <ProfileLineDetails
              title="Equipe"
              subtitle={userProfile?.team?.name ?? "-"}
            />
          </Flex>
          <Text fontSize="md" color="gray">
            {userProfile?.bio}
          </Text>
        </Flex>
      </SimpleCard>
    </Flex>
  );
}
