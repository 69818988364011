import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

export const PageHeading = ({ title, subtitle }: {title: String, subtitle: String}) => (
    <Flex flexDir="column" mb="40px">
        <Heading
            as="h2"
            mb="4px"
        >
            {title}
        </Heading>
        <Text color="gray">
            {subtitle}
        </Text>
    </Flex>
)