import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spacer,
  Text,
  useColorModeValue,
  useStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import SimpleCard from "../../components/cards/SimpleCard";
import { PageHeading } from "../../components/PageHeading";
import {
  getProfilesConnectedToCurrentUser,
  ProfileModelInterface,
} from "../../dal/supabase/models/ProfileModel";

export default function Users() {
  const [filterInput, setFilterInput] = useState("");
  const [usersList, setUsersList] = useState<ProfileModelInterface[]>([]);
  const cardStyles = useStyleConfig("CustomCard");
  const overlayBoxBgColor = useColorModeValue(
    "blackAlpha.500",
    "blackAlpha.700"
  );

  console.log("Videos data:");
  console.log(usersList);
  /*console.log(count);
    console.log(data)
    console.log(error)
    console.log(fetching)*/

  //let match = useRouteMatch();

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  useEffect(() => {
    async function getAllUsersRelatedToCurrentUser() {
      let usersAll = await getProfilesConnectedToCurrentUser();
      setUsersList(usersAll.data);
    }

    getAllUsersRelatedToCurrentUser();
  }, []);
  return (
    <Flex>
      <Flex w="full" flexDir="column">
        <Flex flexDir="row">
          <PageHeading
            title="Usuários"
            subtitle="Veja os usuários conectados ou adicione um novo"
          />
          <Spacer />
          <Button
            as={NavLink}
            to={`/addVideo`}
            aria-label="refresh"
            mr="4"
            fontSize="md"
            bg="brand.primary"
            color="white"
            _hover={{
              bg: "purple.400",
              color: "white",
            }}
            onClick={() => console.log("Adicionar usuário")}
          >
            Adicionar Usuário
          </Button>
        </Flex>
        <InputGroup mb="20px">
          <InputLeftElement color="gray.500" children={<FiSearch />} />
          <Input
            //p={4}
            sx={cardStyles}
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Pesquise o usuário pelo nome ou username..."}
          />
        </InputGroup>
        <SimpleGrid
          gap="20px"
          columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
        >
          {usersList
          .filter(
            (user) =>
              filterInput === undefined ||
              user.name
                .toLowerCase()
                .search(filterInput.toLowerCase()) >= 0 ||
              user.username
                .toLowerCase()
                .search(filterInput.toLowerCase()) >= 0
          )
          .map((userProfile, index) => (
            <SimpleCard
              key={index}
              children={
                <>
                  <Center flexDir="column" p={4}>
                    <Avatar name={userProfile.name} src={userProfile.avatar_url}/>
                    <Text pt={2}>{userProfile.name}</Text>
                  </Center>
                  <Divider/>
                  <Box p={4}>
                    <Text as="h4" fontSize="sm" noOfLines={3}>
                      Username: {userProfile.username ?? "-"}
                    </Text>
                    <Text as="h4" fontSize="sm" noOfLines={3}>
                      Empresa: {userProfile.company?.name ?? "-"}
                    </Text>
                    <Text as="h4" fontSize="sm" noOfLines={3}>
                      Equipe: {userProfile.team?.name ?? "-"}
                    </Text>
                  </Box>
                </>
              }
              hoveringChildren={
                <Box
                  backgroundColor={overlayBoxBgColor}
                  position="absolute"
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  h="full"
                  w="full"
                  top={0}
                  left={0}
                >
                  <Button
                    as={NavLink}
                    bg="brand.primary"
                    color="white"
                    p={8}
                    to={`/users/${userProfile.id}`}
                    _hover={{
                      bg: "gray.500",
                      color: "white",
                    }}
                  >
                    Selecionar
                  </Button>
                </Box>
              }
            />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}
