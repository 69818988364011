import {
  Grid,
  Heading,
  Flex,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { FiFile, FiTerminal, FiX } from "react-icons/fi";
import InfoCard, { InfoCardProps } from "../components/dashboard/InfoCard";
import TableCard from "../components/dashboard/TableCard";
import { currentUser } from "../components/database/User";

const dataList: Array<InfoCardProps> = [
  {
    title: "Empresas",
    subtitle: "10",
    icon: FiFile,
    iconBg: "gray.500",
  },
  {
    title: "Equipes",
    subtitle: "27",
    icon: FiTerminal,
    iconBg: "green.400",
  },
  {
    title: "Pessoas",
    subtitle: "184",
    icon: FiX,
    iconBg: "purple.400",
  },
];

export const Dashboard = () => (
  <Flex textAlign="center" fontSize="xl" flexDirection="column" gridGap="40px">
    <Flex flexDir="column" gridGap={8} mt={20}>
      <Heading textAlign="left" as="h3" size="lg" noOfLines={1}>
        Bem vindo, {currentUser.name}
      </Heading>
    </Flex>
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(8, 1fr)"
      gridGap="40px"
    >
      {dataList.map(function (data, index) {
        return (
          <GridItem key={index} colSpan={{ sm: 8, lg: 4, "2xl": 2 }}>
            <InfoCard props={data} />
          </GridItem>
        );
      })}
      <GridItem colSpan={{ sm: 8, lg: 8, "2xl": 8 }}>
        <TableCard title="Grupos" />
      </GridItem>
    </Grid>
  </Flex>
);
