import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Box,
  useDisclosure,
  Drawer,
  DrawerContent,
  useStyleConfig,
  extendTheme,
  theme as baseTheme,
  Avatar,
  Flex,
  Icon,
  IconButton,
  MenuButton,
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuGroup,
  Text,
  Center,
} from "@chakra-ui/react";

import { mode } from "@chakra-ui/theme-tools";
/*import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
} from 'react-icons/fi';*/

//import { IconType } from 'react-icons';

import { BrowserRouter, NavLink, Route, Switch } from "react-router-dom";

import { SidebarContent } from "./components/sidebar/SidebarContent";
import { Dashboard } from "./pages/Dashboard";
import { Videos } from "./pages/Videos";
import { LinkVideos } from "./pages/LinkVideos";
import Login from "./pages/Login";
import { auth } from "./firebase";
import { UserData, useUserProfile } from "./components/database/User";

import { DashboardCompany } from "./pages/Company/DashboardCompany";
import { DashboardUser } from "./pages/DashboardUser";

import { Provider } from "react-supabase";
import { supabase } from "./supabaseClient";
import { createClient, Session } from "@supabase/supabase-js";
import SignUp from "./pages/SignUp";
import VideosLibrary from "./pages/VideosLibrary";
import Challenges from "./pages/Challenges/Challenges";
import Training from "./pages/Training/Training";
import AddTraining from "./pages/Training/addTraining";
import AddChallenge from "./pages/Challenges/AddChallenge";

import { FaBell } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { currentUser } from "../src/components/database/User";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import PasswordUpdate from "./pages/PasswordUpdate";
import Companies from "./pages/Company/Companies";
import AddCompany from "./pages/Company/AddCompany";
import Users from "./pages/Users/Users";
import Profile from "./pages/Users/Profile";
import EditTraining from "./pages/Training/editTraining";

const supabaseUrl = "https://ycrkyrxfoompuldgufxw.supabase.com";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJvbGUiOiJhbm9uIiwiaWF0IjoxNjQyODg0ODUyLCJleHAiOjE5NTg0NjA4NTJ9.jj14nXOQM3odv-qeweh4ThW3gsD6Yo4VqFQ9TPUePLA";

const client = createClient(supabaseUrl, supabaseAnonKey);

// Extending chakra Theme to suport brand color custom values
const theme = extendTheme({
  colors: {
    brand: {
      primary: baseTheme.colors.purple[600],
      secondary: "#1E1E1E",
      bgPrimary: "#AAAAAA",
      bgSecondary: "#DBDBDB",
    },
    brandPrimary: {
      50: "#e9eaf9",
      100: "#c7cbef",
      200: "#a3a9e5",
      300: "#7d88da",
      400: "#616cd2",
      500: "#4551c9",
      600: "#3f49be",
      700: "#363fb2",
      800: "#2e34a6",
      900: "#1f1f92",
    },
    brandSecondary: {
      50: "#e7e9f2",
      100: "#c3c9e0",
      200: "#9ca6ca",
      300: "#7784b5",
      400: "#5a69a6",
      500: "#3e5098",
      600: "#38488f",
      700: "#303f83",
      800: "#293577",
      900: "#1d2560",
    },
  },
  components: {
    CustomBadge: {
      baseStyle: ({ colorMode }: any) => ({
        bg: colorMode === "light" ? "gray.50" : "gray.800",
        /*color: colorMode === "dark" ? "gray.800" : "white",
        textTransform: "uppercase",
        fontWeight: "semibold",
        letterSpacing: "0.02em",
        padding: "4px",
        borderRadius: "2px",
        fontSize: "12px",*/
      }),
    },
    CustomHeader: {
      baseStyle: ({ colorMode }: any) => ({
        bg: colorMode === "light" ? "gray.100" : "white",
      }),
    },
    CustomCard: {
      baseStyle: (props: any) => ({
        borderWidth: "1px",
        borderRadius: "lg",
        overflow: "hidden",
        boxShadow: "base",
        backgroundColor: mode("white", "gray.700")(props),
      }),
      variants: {
        primary: (props: any) => ({
          backgroundColor: mode("brandPrimary.600", "brandPrimary.600")(props),
          color: mode("whiteAlpha.800", "whiteAlpha.800")(props),
        }),
      },
    },
    CalendarDay: {
      baseStyle: {
        rounded: "none",
        bgColor: "transparent",

        _hover: {
          bgColor: "gray.400",
        },

        _disabled: (props: any) => ({
          color: "gray.400",
          _hover: {
            cursor: "initial",
            bgColor: "transparent",
          },
        }),
      },

      sizes: {
        sm: {
          h: 8,
        },
      },

      variants: {
        selected: {
          bgColor: "brandPrimary.700",
          color: "white",

          _hover: {
            bgColor: "brandPrimary.600",
          },
        },

        range: {
          bgColor: "brandPrimary.300",
          color: "white",

          _hover: {
            bgColor: "brandPrimary.200",
          },

          _disabled: {
            _hover: {
              bgColor: "brandPrimary.400",
            },
          },
        },

        outside: {
          color: "gray.300",
        },
      },

      defaultProps: {
        size: "sm",
      },
    },
  },
});

// 3. Use it in your components
function CustomBadge(props: any) {
  const { size, variant, ...rest } = props;
  const styles = useStyleConfig("CustomBadge", { size, variant });
  return <Box sx={styles} {...rest} />;
}

export const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userProfile, setUserProfile] = useState<UserData>();
  const user = supabase.auth.user();
  const profile = useUserProfile();
  const headerProps = useStyleConfig("CustomHeader");

  //const [userAuth, loading, error] = useAuthState(auth);
  //const [userData, setUserData] = React.useState(user);
  //const [{ error, fetching, session, user}, signIn] = useSignIn();

  //const [userData, loading1, error1] = useDocumentOnce(userAuth && getDoc(doc(db, COLLECTIONS.user, userAuth.uid)));
  console.log(user);

  const fetchUser = async () => {
    //const _user = await getUser(userAuth.uid);
    console.log("New user:");
    console.log(user);
    //setUserData(_user);
  };

  console.log(supabase.auth.user());
  console.log(profile);
  /*if(userData) {
    console.log("User Data: " + userData);
  }*/

  const userData: UserData = {
    id: "",
    name: "123",
    lastLoggedIn: "123",
    companyRef: "123",
    teamRef: "123",
    pin: "",
    avatarURL: "",
  };

  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    setSession(supabase.auth.session());

    console.log(session)
    console.log(supabase.auth);
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log(_event);
      console.log(session);
      setSession(session);
    });

    return () => {
      authListener!.unsubscribe();
    }
  }, []);

  console.log(session);
  console.log(supabase.auth)
  console.log(supabase.auth.session())
  return (
    <Provider value={supabase}>
      <ChakraProvider theme={theme}>
        <ReactNotifications />
        <CustomBadge
          minH={"100vh"}
          //minW={'100vh'}
          //bg="gray.50"
        >
          {supabase.auth.user() ? (
            !profile.profile && !profile.loading ? (
              <SignUp />
            ) : (
              <BrowserRouter>
                <Box minH="100vh">
                  <SidebarContent
                    onClose={() => onClose}
                    display={{ base: "none", md: "block" }}
                  />
                  <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full"
                  >
                    <DrawerContent>
                      <SidebarContent onClose={onClose} />
                    </DrawerContent>
                  </Drawer>
                  <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
                    <Flex
                      as="header"
                      align="center"
                      justify="space-between"
                      w="full"
                      px="4"
                      borderBottomWidth="1px"
                      borderColor="blackAlpha.300"
                      h="14"
                      sx={headerProps}
                    >
                      <IconButton
                        aria-label="Menu"
                        display={{ base: "inline-flex", md: "none" }}
                        onClick={onOpen}
                        icon={<FiMenu />}
                        size="sm"
                      />
                      {/*}
                      <InputGroup w="96" display={{ base: "none", md: "flex" }}>
                        <InputLeftElement
                          color="gray.500"
                          children={<FiSearch />}
                        />
                        <Input placeholder="Search for articles..." />
                      </InputGroup>
                      */}
                      <Flex />
                      <Flex align="center">
                        <ColorModeSwitcher justifySelf="flex-end" />
                        <Icon color="gray.500" as={FaBell} cursor="pointer" />
                        <Menu>
                          <MenuButton
                            as={Button}
                            rounded={"full"}
                            variant={"link"}
                            cursor={"pointer"}
                            minW={0}
                          >
                            <Avatar
                              ml="4"
                              size="sm"
                              name={currentUser.name}
                              src={currentUser.avatarURL}
                              cursor="pointer"
                            />
                          </MenuButton>
                          <MenuList>
                            <MenuGroup title={currentUser.name}>
                              <Center py={2} flexDir="column">
                                <Avatar
                                  size="lg"
                                  name={currentUser.name}
                                  src={currentUser.avatarURL}
                                />
                                <Text>{user?.email}</Text>
                                <Text>Meu id: {currentUser.id + currentUser.pin}</Text>
                              </Center>
                              <MenuItem>Meu perfil</MenuItem>
                              <MenuItem as={NavLink} to="/changePassword">
                                Trocar senha
                              </MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuItem onClick={() => supabase.auth.signOut()}>
                              Sign Out
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </Flex>

                    <Box as="main" p="4">
                      <Switch>
                        <Route path="/" exact component={Dashboard} />
                        <Route path="/dashboard" exact component={Dashboard} />
                        <Route path="/companies" exact component={Companies} />
                        <Route
                          path="/companies/:companyId"
                          component={DashboardCompany}
                        />
                        <Route
                          path="/addCompany"
                          exact
                          component={AddCompany}
                        />
                        <Route
                          path="/dashboard-user"
                          exact
                          component={DashboardUser}
                        />
                        <Route path="/videos" exact component={VideosLibrary} />
                        <Route path="/addVideo" exact component={Videos} />
                        <Route
                          path="/linkVideos"
                          exact
                          component={LinkVideos}
                        />
                        <Route path="/training" exact component={Training} />
                        <Route
                          path="/training/:trainingId"
                          component={EditTraining}
                        />
                        <Route
                          path="/addTraining"
                          exact
                          component={AddTraining}
                        />
                        <Route
                          path="/challenges"
                          exact
                          component={Challenges}
                        />
                        <Route
                          path="/addChallenge"
                          exact
                          component={AddChallenge}
                        />
                        <Route
                          path="/changePassword"
                          exact
                          component={PasswordUpdate}
                        />
                        <Route path="/users" exact component={Users} />
                        <Route
                          path="/users/:userId"
                          component={Profile}
                        />
                      </Switch>
                    </Box>
                  </Box>
                </Box>
              </BrowserRouter>
            )
          ) : (
            <Login />
          )}
        </CustomBadge>
      </ChakraProvider>
    </Provider>
  );
};
