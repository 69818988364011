import React from 'react'
import { Line } from 'react-chartjs-2'

const data = {
    labels: ['01/09/2021', '02/09/2021', '03/09/2021', '04/09/2021', '05/09/2021', '06/09/2021', '07/09/2021', '08/09/2021', '09/09/2021', '10/09/2021', '11/09/2021', '12/09/2021', '13/09/2021'],
    datasets: [
        {
            label: 'Postura',
            fill: false,
            lineTension: 0.5,
            backgroundColor: '#db86b2',
            borderColor: '#B57295',
            borderCapStyle: 'butt',
            borderDashOffset: 0.0,
            borderJoinStyle: '#B57295',
            pointBorderColor: '#B57295',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#B57295',
            pointHoverBorderColor: '#B57295',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [10, 30, 40, 80, 60, 75, 90, 86, 88, 80, 72, 42, 60],
        },
    ],
}

const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                borderDash: [3, 3],
            },
            // beginAtZero: true, // this works
        },
    },
    plugins: {
        legend: {
            display: false
        }
    }
}

const PostureChart = () => (
    <Line 
        data={data}
        options={options}
    />
)


export default PostureChart