import { supabase } from "../../../supabaseClient";
import { VideoModelInterface } from "./VideoModel";

interface AddTrainingInterface {
  title: string;
  description: string;
  days: number;
  user_id: string;
}

interface AddDailyExerciseInterface {
  training_day: number;
  day_order: number;
  training_id: number;
  video_id: number;
}

export interface ExtendedTrainingModelInterface extends TrainingModelInterface {
  daily_exercise: Array<ExtendedDailyExerciseModelInterface>
}

export interface ExtendedDailyExerciseModelInterface extends DailyExerciseModelInterface{
  video: VideoModelInterface
}
export interface TrainingModelInterface extends AddTrainingInterface {
  id: number;
  created_at: Date;
}

export interface DailyExerciseModelInterface {
  id?: number;
  created_at?: Date;
  training_day: number;
  day_order: number;
  training_id: number;
  video_id: number;
}

export interface DailyExercises {
  day: number;
  exercises: Array<VideoModelInterface>;
}

export interface Training {
  training: AddTrainingInterface;
  dailyExercises: Array<DailyExercises>;
}

export async function addTraining(training: Training) {
  let { data, error } = await supabase
    .from("training")
    .insert(training.training);

  if (error || data?.length === 0) return { data, error };

  let addedTraining = data && (data[0] as TrainingModelInterface);
  console.log(addedTraining);
  /**
   * Map dailyExercises to an array of AddDailyExerciseInterface
   * Reduce the map of arrays to one big array to be added
   */
  let flatenedData = training.dailyExercises.flatMap((dailyExercise) => {
    return dailyExercise.exercises.map((exercise, index) => {
      return {
        training_day: dailyExercise.day,
        training_id: addedTraining?.id,
        day_order: index,
        video_id: exercise.id
      } as AddDailyExerciseInterface
    })
  })

  console.log(flatenedData);

  //After added training we should add the daily exercises and link them
  return await supabase
    .from("daily_exercise")
    .insert(flatenedData, {
      returning: 'minimal'
  });
}

export async function addDailyExercise(exercises: AddDailyExerciseInterface) {}

export async function GetTrainingDetails(trainingId: number) {
  /*const { data, error } = await supabase
    .from("training")
    .select(`
      *,
      daily_exercise:daily_exercise(
        *,
        video:videos(
          *,
          tags(id, tag)
        )
      )
      `)
    .eq("id", trainingId);
    //.order("training_day, day_order");*/

    const { data, error } = await supabase
    .from("training")
    .select(`
      *,
      daily_exercise:daily_exercise(
        *,
        video:videos(
          *,
          tags:tags_videos(tag:tags(id,tag)))
        )
      )
      `)
    .eq("id", trainingId);

    //let tags = data?.map((e) => e.daily_exercise.map((b: any) => b.video.tags_videos.map((a: { tags: any; }) => a.tags))) || [];
    //console.log(tags);

  let dailyExerciseList =
    data?.map((video) => video as ExtendedTrainingModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: dailyExerciseList, error };
}

export async function GetTrainingsFromUser() {
  const { data, error } = await supabase
    .from("training")
    .select(`
      *,
      daily_exercise:daily_exercise(
        *,
        video:videos(
          *
        )
      )
      `)
    .eq("user_id", supabase.auth.user()?.id);

  let trainingList =
    data?.map((video) => video as TrainingModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: trainingList, error };
}
