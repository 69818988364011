import { supabase } from "../../../supabaseClient";
import { VideoModelInterface } from "./VideoModel";

export interface ChallengeModelInterface extends ChallengeInterface{
  id: number;
  created_at: Date;
  video: VideoModelInterface;
}

interface ChallengeInterface {
  expires_at?: Date;
  title: string;
  description: string;
  video_id: number;
  user_id: string;
}

export async function addChallenge(challenge: ChallengeInterface) {
  return await supabase.from("challenges").insert(challenge, {
    returning: "minimal",
  });
}

export async function GetChallengesFromUser() {
  const { data, error } = await supabase
    .from("challenges")
    .select("*,video:videos(*)")
    .eq("user_id", supabase.auth.user()?.id);

  let challengeList =
    data?.map((video) => video as ChallengeModelInterface) || [];
  console.log("Data:");
  console.log(data);
  return { data: challengeList, error };
}
