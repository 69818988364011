import {
  Box,
  Grid,
  useColorModeValue,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Text,
  Select,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Input,
  useStyleConfig,
  FormControl,
  FormLabel,
  Divider,
  Flex,
  GridItem,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FiFile, FiHome, FiTerminal, FiX } from "react-icons/fi";
import { Store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import TrainingCard from "../../components/cards/TrainingCard";
import InfoCard, { InfoCardProps } from "../../components/dashboard/InfoCard";
import PostureChart from "../../components/dashboard/PostureChart";
import TrainingPickerDrawer from "../../components/TrainingPickerDrawer";
import {
  CompanyTeamsUsersModelInterface,
  getCompanyById,
  getCompanyTeams,
  updateTrainingFromCompany,
} from "../../dal/supabase/models/CompaniesModel";
import { getRankingFromCompanyById, RankingModelInterface } from "../../dal/supabase/models/RankingModel";
import { addTeam } from "../../dal/supabase/models/TeamsModel";
import {
  GetTrainingsFromUser,
  TrainingModelInterface,
} from "../../dal/supabase/models/TrainingModel";
import { supabase } from "../../supabaseClient";

export interface CompanyProps {
  company: string;
  stretching: string;
  challenges: string;
  level: string;
  teams: Array<CompanyTeamProps>;
}

export interface CompanyTeamProps {
  team: string;
  users: Array<UserProps>;
}

export interface UserProps {
  name: string;
  posture: number;
  points: number;
  challenges: number;
  stretching: number;
  level: number;
}

const companyData: CompanyProps = {
  company: "Intelbras",
  stretching: "40%",
  challenges: "30%",
  level: "6",
  teams: [
    {
      team: "Marketing",
      users: [
        {
          name: "Guilherme Damasio",
          posture: 70,
          points: 712,
          challenges: 40,
          stretching: 30,
          level: 8,
        },
        {
          name: "Alexandre Turozi",
          posture: 72,
          points: 687,
          challenges: 48,
          stretching: 45,
          level: 7,
        },
        {
          name: "Gemma Coulson",
          posture: 64,
          points: 680,
          challenges: 20,
          stretching: 60,
          level: 6,
        },
        {
          name: "Mimi Worthington",
          posture: 80,
          points: 542,
          challenges: 80,
          stretching: 60,
          level: 7,
        },
        {
          name: "Alisha Melton",
          posture: 40,
          points: 631,
          challenges: 52,
          stretching: 28,
          level: 8,
        },
      ],
    },
    {
      team: "Engenharia",
      users: [
        {
          name: "Habib Parsons",
          posture: 54,
          points: 831,
          challenges: 44,
          stretching: 32,
          level: 5,
        },
        {
          name: "Junaid Copeland",
          posture: 71,
          points: 452,
          challenges: 65,
          stretching: 53,
          level: 4,
        },
        {
          name: "Habib Parsons",
          posture: 92,
          points: 926,
          challenges: 79,
          stretching: 92,
          level: 5,
        },
      ],
    },
  ],
};

const TeamQty = companyData.teams.length;
const UsersQty = companyData.teams
  .map((team) => team.users.length)
  .reduce((partial_sum, val) => partial_sum + val, 0);

const dataListInfoCards: Array<InfoCardProps> = [
  {
    title: "Alongamentos",
    subtitle: `${
      companyData.teams
        .map((team) =>
          team.users
            .map((e) => e.stretching)
            .reduce((partial_sum, val) => partial_sum + val, 0)
        )
        .reduce((partial_sum, val) => partial_sum + val, 0) / UsersQty
    }%`,
    icon: FiFile,
    iconBg: "gray.500",
  },
  {
    title: "Desafios",
    subtitle: `${
      companyData.teams
        .map((team) =>
          team.users
            .map((e) => e.challenges)
            .reduce((partial_sum, val) => partial_sum + val, 0)
        )
        .reduce((partial_sum, val) => partial_sum + val, 0) / UsersQty
    }%`,
    icon: FiTerminal,
    iconBg: "green.400",
  },
  {
    title: "Quantidade de Pessoas",
    subtitle: `${UsersQty}`,
    icon: FiX,
    iconBg: "purple.400",
  },
  {
    title: "Nivel",
    subtitle: `${
      companyData.teams
        .map((team) =>
          team.users
            .map((e) => e.level)
            .reduce((partial_sum, val) => partial_sum + val, 0)
        )
        .reduce((partial_sum, val) => partial_sum + val, 0) / UsersQty
    }%`,
    icon: FiHome,
    iconBg: "gray.400",
  },
];

async function addNewTeam(values: any, actions: any) {
  console.log(values);

  if (supabase.auth.user() === null) {
    Store.addNotification({
      title: "Erro ao adicionar a equipe!",
      message: `Não logado. Favor logar`,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    return;
  }

  const { error } = await addTeam({
    name: values.team,
    company_id: values.company.id,
  });

  console.log(error);

  if (error) {
    Store.addNotification({
      title: "Erro ao adicionar a equipe!",
      message: `${error.message}. Código ${error.code}`,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  } else {
    Store.addNotification({
      title: "Equipe adicionada!",
      message: "A equipe foi adicionada com sucesso",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  }

  actions.setSubmitting(false);
}

async function linkTrainingToCompany(companyId: number, trainingId: number) {
  console.log({company_id: companyId, training_id: trainingId});

  if (supabase.auth.user() === null) {
    Store.addNotification({
      title: "Erro ao linkar treino a empresa!",
      message: `Não logado. Favor logar`,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    return;
  }

  const { error } = await updateTrainingFromCompany({
    companyId: companyId,
    trainingId: trainingId,
  });

  console.log(error);

  if (error) {
    Store.addNotification({
      title: "Erro ao linkar o treino a equipe!",
      message: `${error.message}. Código ${error.code}`,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  } else {
    Store.addNotification({
      title: "Treino linkado a empresa!",
      message: "O treino foi linkado a empresa com sucesso",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  }

  //actions.setSubmitting(false);
}

export const DashboardCompany = ({ match }: { match: any }) => {
  const [company, setCompany] = useState<CompanyTeamsUsersModelInterface>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const trainingDisclosure = useDisclosure();
  const [trainingList, setTrainingList] = useState<
    Array<TrainingModelInterface>
  >([]);
  const [selectedTraining, setSelectedTraining] =
    useState<TrainingModelInterface>();
  const cardsStyle = useStyleConfig("CustomCard");
  const params = useParams();
  const [ranking, setRanking] = useState<Array<RankingModelInterface>>();

  useEffect(() => {
    async function getCompany() {
      await getCompanyById(match.params.companyId);
      let company = await getCompanyTeams(match.params.companyId);
      let trainings = await GetTrainingsFromUser();
      let ranking = await getRankingFromCompanyById(match.params.companyId);
      console.log(company);
      //console.log(match)
      setCompany(company.data[0]);
      setSelectedTraining(company.data[0].training);
      setTrainingList(trainings.data);
      setRanking(ranking.data);
    }

    getCompany();
  }, []);

  return (
    <Flex textAlign="center" fontSize="xl" flexDirection="column">
      <TrainingPickerDrawer
        isOpen={trainingDisclosure.isOpen}
        onOpen={trainingDisclosure.onOpen}
        onClose={trainingDisclosure.onClose}
        trainingList={trainingList}
        onSelect={(training) => {
          console.log(training);
          setSelectedTraining(training);
          onClose();
        }}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crie uma equipe</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                team: undefined,
                company: company,
              }}
              onSubmit={(values, actions) => {
                addNewTeam(values, actions);
              }}
            >
              {(props) => (
                <Form>
                  <Field name="team">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.team && form.touched.team}
                      >
                        <FormLabel htmlFor="team">Nome da Equipe</FormLabel>
                        <Input
                          sx={cardsStyle}
                          {...field}
                          id="teamName"
                          placeholder="Nome da equipe"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Flex mt={4} gridGap={4} justifyContent="flex-end">
                    <Button onClick={onClose}>Cancelar</Button>
                    <Button
                      bg="brand.primary"
                      color="white"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Cadastrar Equipe
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(8, 1fr)"
        gridGap="40px"
      >
        <GridItem colSpan={{ sm: 8, lg: 4, "2xl": 4 }}>
          <Heading as="h2" textAlign="left">
            {company?.name}
          </Heading>
        </GridItem>
        <GridItem
          colSpan={{ sm: 8, lg: 3, "2xl": 3 }}
          colStart={{ sm: 1, lg: 6, "2xl": 6 }}
        >
          <Select placeholder="Escolha uma opção">
            <option value="30">30 dias</option>
            <option value="60">60 dias</option>
            <option value="90">90 dias</option>
          </Select>
        </GridItem>

        {dataListInfoCards.map(function (data, index) {
          return (
            <GridItem key={index} colSpan={{ sm: 8, lg: 4, "2xl": 2 }}>
              <InfoCard props={data} />
            </GridItem>
          );
        })}

        <GridItem colSpan={{ sm: 8, lg: 4, "2xl": 2 }}>
          <InfoCard
            props={{
              title: "Quantidade de Equipes",
              subtitle: company?.teams.length.toString() ?? "",
              icon: FiFile,
              iconBg: "gray.500",
            }}
          />
        </GridItem>

        <GridItem colSpan={{ sm: 8, lg: 4, "2xl": 2 }}>
          <InfoCard
            props={{
              title: "Quantidade de Pessoas",
              subtitle: company?.users.length.toString() ?? "",
              icon: FiX,
              iconBg: "purple.400",
            }}
          />
        </GridItem>

        <GridItem colSpan={{ sm: 8, lg: 8, "2xl": 3 }}>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="base"
            bg={useColorModeValue("white", "gray.700")}
          >
            <Flex
              flexDir={{ base: "column", sm: "column", md: "row" }}
              justify="space-between"
              align="center"
            >
              <Heading
                p={6}
                as="h6"
                fontSize="md"
                color="gray.500"
                textAlign="left"
              >
                Treinos da Empresa
              </Heading>
              <Button
                aria-label="refresh"
                mr="4"
                fontSize="md"
                bg="brand.primary"
                color="white"
                _hover={{
                  bg: "purple.400",
                  color: "white",
                }}
                onClick={trainingDisclosure.onOpen}
              >
                Escolher um treino
              </Button>
            </Flex>
            <Divider />
            <Box p={6}>
              {selectedTraining === undefined || selectedTraining === null? (
                <Text>
                  Essa empresa não tem um treino ainda. Adicione um treino a
                  essa empresa.
                </Text>
              ) : (
                <Stack gridGap={4}>
                  <TrainingCard
                    training={selectedTraining}
                    props={{
                      maxW: 400,
                    }}
                  />
                  <Button onClick={() => linkTrainingToCompany(company?.id || -1, selectedTraining.id)}>Linkar treino a empresa</Button>
                </Stack>
              )}
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={{ sm: 8, lg: 8, "2xl": 3 }}>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="base"
            bg={useColorModeValue("white", "gray.700")}
          >
            <Flex
              flexDir={{ base: "column", sm: "column", md: "row" }}
              justify="space-between"
              align="center"
            >
              <Heading
                p={6}
                as="h6"
                fontSize="md"
                color="gray.500"
                textAlign="left"
              >
                Equipes
              </Heading>
              <Button
                aria-label="refresh"
                mr="4"
                fontSize="md"
                bg="brand.primary"
                color="white"
                _hover={{
                  bg: "purple.400",
                  color: "white",
                }}
                onClick={onOpen}
              >
                Criar uma equipe
              </Button>
            </Flex>
            <Divider />
            <Box p={6}>
              <Table variant="simple">
                <TableCaption placement="top">
                  Lista de pessoas por equipe
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Equipe</Th>
                    <Th>Código</Th>
                    <Th isNumeric>Quantidade de Pessoas</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {company?.teams.map(function (data, index) {
                    return (
                      <Tr key={index}>
                        <Td>{data.name}</Td>
                        <Td>{data.id.toString() + data.pin}</Td>
                        <Td isNumeric>{data.users.length}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </GridItem>
        <GridItem colSpan={{ sm: 8, lg: 8, "2xl": 5 }}>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="base"
            bg={useColorModeValue("white", "gray.700")}
          >
            <Heading
              p={6}
              as="h6"
              fontSize="md"
              color="gray.500"
              textAlign="left"
            >
              Gráfico da postura
            </Heading>
            <Divider />
            <Box maxH="350px" p={6}>
              <PostureChart />
            </Box>
          </Box>
        </GridItem>
        <GridItem colSpan={8}>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="base"
            bg={useColorModeValue("white", "gray.700")}
          >
            <Heading
              p={6}
              as="h6"
              fontSize="md"
              color="gray.500"
              textAlign="left"
            >
              Ranking
            </Heading>
            <Divider />
            <Box p={6}>
              <Table variant="simple">
                <TableCaption placement="top">Ranking da Empresa</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Pessoa</Th>
                    <Th>Equipe</Th>
                    <Th>Postura</Th>
                    <Th isNumeric>Pontos</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {ranking?.map((user, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{user.name}</Td>
                        <Td>
                          {company?.teams.find(
                            (team) => team.id === user.team
                          )?.name ?? "-"}
                        </Td>
                        <Td>{(35 + Math.random() * (100 - 35)).toFixed(0)}%</Td>
                        <Td isNumeric>
                          {
                            user.score
                          }
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Flex>
  );
};
