import { PostgrestError } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';

export interface UserData {
  id: string,
  name: string,
  lastLoggedIn: string,
  companyRef: string,
  teamRef: string,
  pin: string,
  avatarURL: string,
}

export var currentUser: UserData = {
  id: "",
  name: "",
  pin: "",
  lastLoggedIn: "",
  companyRef: "",
  teamRef: "",
  avatarURL: "",
};

export const useUserProfile = () => {
  console.log("Start");
  const user = supabase.auth.user();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<UserData>();
  const [error, setError] = useState<PostgrestError>();

  useEffect(() => {
    async function getUser(){
      setLoading(true);

      const { data, error } = await supabase
        .from('profiles')
        .select()
        .eq('user_id', user?.id)
        .limit(1)
        .single()

      if(data){
        console.log(data);
        setProfile({
          id: data.user_id,
          ...data
        } as UserData);

        currentUser = {
          id: data.user_id,
          ...data
        } as UserData;
      }

      if(error){
        setError(error);
      }

      setLoading(false);
    }

    if(user) {
      getUser();
    }
  }, [user])
  
  console.log(profile);
  
  return {loading, profile, error};
};

/*export const getUser = async (userId : string): Promise<UserData> => {
  console.log("Start");
  const docRef = doc(db, COLLECTIONS.user, userId);
  //const docRef = doc(db, "users", "8w82BEqAFmVE0Njg1yRAW8jHSfF2");
  const docSnap = await getDoc(docRef);

  console.log("data");
  console.log(docSnap.data());
  //console.log(docSnap.data());
  return {
      id: docSnap.id, // because id field in separate function in firestore
      ...docSnap.data(), // the remaining fields
    } as UserData
};*/
