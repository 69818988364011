import { supabase } from "../../../supabaseClient";
import { TagsModelInterface } from "./TagsModel";

interface TagInterface {
    tag: TagsModelInterface
}
interface AddVideoInterface {
    title?: string;
    description?: string;
    youtube_id: string;
    user_id?: string;
    tags?: Array<{tag: TagsModelInterface}>
}

export interface VideoModelInterface extends AddVideoInterface {
    id: number;
    added_at: Date;
}

export async function addVideo(video: AddVideoInterface) {
    return await supabase.from("videos").insert(video)
}

/* TODO: Test this function*/
export async function addTagsToVideo(videoID: number, tags: Array<number>) {
    return await supabase.from("tags_videos").insert(tags.map((tag) => ({tags_id: tag, video_id: videoID})));
}

export async function GetVideosFromUser() {
    /*const [{ count, data, error, fetching }, reexecute] = useSelect("videos", {
        filter: (query) => query.eq('user_id', currentUser.id)
    })

    return [{count, data, error, fecthing}, reexecute];*/
    //const { data, error } = await supabase.from("videos").select('*, tags(id, tag)').eq('user_id', supabase.auth.user()?.id);
    const { data, error } = await supabase.from("videos").select('*, tags:tags_videos(tag:tags(id,tag)))').eq('user_id', supabase.auth.user()?.id);

    let videoList = data?.map((video) => video as VideoModelInterface) || [];
    console.log("Data:");
    console.log(data);
    console.log(error);
    return {data: videoList, error};
}