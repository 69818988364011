import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  useStyleConfig,
  Divider,
  SimpleGrid,
  Box,
  Button,
  DrawerFooter,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { VideoModelInterface } from "../dal/supabase/models/VideoModel";
import VideoCard from "./cards/VideoCard";

interface VideoPickerDrawerInterface {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSelect: (video: VideoModelInterface) => void;
  videosList: Array<VideoModelInterface>;
}

export default function VideoPickerDrawer(props: VideoPickerDrawerInterface) {
  const [filterInput, setFilterInput] = useState("");
  const { isOpen, onClose, videosList, onSelect } = props;

  const overlayBoxBgColor = useColorModeValue(
    "blackAlpha.500",
    "blackAlpha.700"
  );

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Escolha um vídeo</DrawerHeader>

        <DrawerBody>
          <Input
            sx={useStyleConfig("CustomCard")}
            p={6}
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Pesquise o vídeo..."}
          />
          <Divider my={4} />
          <SimpleGrid columns={2} gridGap={8}>
            {videosList
              /*.filter(
              (fbPg: UserInterface) =>
                !filterInput ||
                fbPg.name.toLowerCase().includes(filterInput.toLowerCase())
            )*/
              .map((videoData, index) => (
                <VideoCard
                  key={"video-card-" + index}
                  video={videoData}
                  hoveringChildren={
                    <Box
                      backgroundColor={overlayBoxBgColor}
                      position="absolute"
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                      h="full"
                      w="full"
                      top={0}
                      left={0}
                    >
                      <Button
                        bg="brand.primary"
                        color="white"
                        p={8}
                        onClick={(e) => onSelect(videoData)}
                        _hover={{
                          bg: "gray.500",
                          color: "white",
                        }}
                      >
                        Selecionar
                      </Button>
                    </Box>
                  } /*props={{maxW: 100}}*/
                />
              ))}
          </SimpleGrid>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancelar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
