import React from 'react'
import { Avatar, Box, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { IconType } from 'react-icons';

export interface InfoCardProps {
    title: string;
    subtitle: string;
    icon: IconType;
    iconBg: string;
}

export default function InfoCard({ props }: { props: InfoCardProps }) {
    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="base"
            bg={useColorModeValue('white', 'gray.700')}
            p={6}
            h="full"
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" textAlign="left">
                    <Text as="h6" fontSize="md" color="gray.500" textTransform="uppercase">{props.title}</Text>
                    <Text as="h3" fontSize="xl">{props.subtitle}</Text>
                </Stack>
                <Avatar
                    background={props.iconBg}
                    size="lg"
                >
                    {props.icon}
                </Avatar>
            </Stack>
        </Box>
    )
}