import React from "react"
import {
    Flex,
    Icon,
    Link,
    FlexProps,
  } from '@chakra-ui/react';

import { IconType } from 'react-icons';
import { NavLink } from 'react-router-dom';

interface NavItemProps extends FlexProps {
    icon?: IconType;
    path?: string;
    children: any;
}

export const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
    return (
        path ? <Link as={NavLink} to={path} style={{ textDecoration: 'none' }} _activeLink={{color:'blackAlpha.700', fontWeight:"bolder"}}>
            <FlexChildItem icon={icon} path={path} children={children} {...rest}/>
        </Link>
        :
        <FlexChildItem icon={icon} path={path} children={children} {...rest}/>
    );
};

const FlexChildItem = ({ icon, path, children, ...rest }: NavItemProps) => {
    return (
        <Flex
                align="center"
                p="4"
                mx="2"
                rounded="md"
                role="group"
                cursor="pointer"
                fontWeight="semibold"
                _hover={{
                    bg: 'blackAlpha.300',
                    color: 'whiteAlpha.900',
                }}
                //background={{bg: 'purple.400', color: 'white'}}
                transition=".15s ease"
                {...rest}>
                {icon && (
                    <Icon
                        mr="2"
                        boxSize={4}
                        _groupHover={{
                            color: 'gray.300',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
    )
}