import React from 'react'
import { Box, Flex, Heading, Input, InputGroup, InputLeftAddon, Text, useColorModeValue } from "@chakra-ui/react"

export const LinkVideosCard = ({ weekDay }: { weekDay: String }) => (
    <Box
        w={{ base: 'full', md: 'xs' }}
        boxShadow="base"
        rounded="lg"
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius="lg"
        minW="xs"
        border="1px"
        borderColor={useColorModeValue('gray.100', 'gray.600')}
        overflow="hidden"
    >
        <Heading as="h4" fontSize="md" py="8" textAlign="center" bg={useColorModeValue('gray.100', 'gray.600')}>{weekDay}</Heading>
        <Box p="4">
            <Flex flexDir="column" gridGap="4px">
                <Text mt="4">Postura Ótima</Text>
                <InputGroup>
                    <InputLeftAddon children="#1" />
                    <Input id={weekDay + "-verygood-1"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <Text mt="4">Postura Boa</Text>
                <InputGroup>
                    <InputLeftAddon children="#1" />
                    <Input id={weekDay + "-good-1"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <Text mt="4">Postura Ruim</Text>
                <InputGroup>
                    <InputLeftAddon children="#1" />
                    <Input id={weekDay + "-bad-1"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children="#2" />
                    <Input id={weekDay + "-bad-2"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children="#3" />
                    <Input id={weekDay + "-bad-3"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children="#4" />
                    <Input id={weekDay + "-bad-4"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <Text mt="4">Postura Péssima</Text>
                <InputGroup>
                    <InputLeftAddon children="#1" />
                    <Input id={weekDay + "-verybad-1"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children="#2" />
                    <Input id={weekDay + "-verybad-2"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children="#3" />
                    <Input id={weekDay + "-verybad-3"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children="#4" />
                    <Input id={weekDay + "-verybad-4"} placeholder="aqz-KE-bpKQ" />
                </InputGroup>
            </Flex>
        </Box>
    </Box>
)