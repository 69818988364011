import { supabase } from "../../../supabaseClient";
import { ProfileModelInterface } from "./ProfileModel";

export interface TeamUsersModelInterface extends TeamModelInterface {
    users: ProfileModelInterface[];
}

export interface TeamModelInterface extends TeamInterface {
  id: number   /* primary key */;
  created_at: Date;
}

interface TeamInterface {
  name: string;
  company_id: number;
  pin?: string;
}

export async function addTeam(team: TeamInterface) {
  return await supabase.from("teams").insert(team, {
    returning: "minimal",
  });
}

export async function GetUsersFromTeam(teamId: number) {
  const { data, error } = await supabase
    .from("profiles")
    .select("*")
    .eq("team", teamId);

  /*let companiesList =
    data?.map((video) => video as CompanyModelInterface) || [];
  console.log("Data:");
  console.log(data);*/
  return { data: data, error };
}

export async function getTeamsFromCompany(companyId: number) {
    const { data, error } = await supabase
    .from("teams")
    .select("*")
    .eq("company", companyId);

    let teamsList =
    data?.map((team) => team as TeamModelInterface) || [];
  console.log("Data:");
  console.log(data);

  return { data: teamsList, error };
}
