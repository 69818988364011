import {
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spacer,
  useStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useRouteMatch, NavLink } from "react-router-dom";
import VideoCard from "../../components/cards/VideoCard";
import { PageHeading } from "../../components/PageHeading";
import { ChallengeModelInterface, GetChallengesFromUser } from "../../dal/supabase/models/Challenges";

export default function Challenges() {
  const [filterInput, setFilterInput] = useState("");
  const [challengesList, setChallengesList] = useState<Array<ChallengeModelInterface>>([]);
  const cardStyles = useStyleConfig("CustomCard");

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  useEffect(() => {
    async function getAllChallenges() {
      let challengesAll = await GetChallengesFromUser();
      setChallengesList(challengesAll.data);
    }

    getAllChallenges();
  }, []);
  
  console.log()
  return (
    <Flex>
      <Flex w="full" flexDir="column">
        <Flex flexDir="row">
          <PageHeading
            title="Desafios"
            subtitle="Crie um desafio para os usuários da plataforma"
          />
          <Spacer />
          <Button
            as={NavLink}
            to={`/addChallenge`}
            aria-label="refresh"
            mr="4"
            fontSize="md"
            bg="brand.primary"
            color="white"
            _hover={{
              bg: "purple.400",
              color: "white",
            }}
            onClick={() => console.log("Creating challenges")}
          >
            Criar um Desafio
          </Button>
        </Flex>
        <InputGroup  mb="20px">
          <InputLeftElement color="gray.500" children={<FiSearch />} />
          <Input
            //p={4}
            sx={cardStyles}
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Pesquise o vídeo pelo nome..."}
          />
        </InputGroup>
        <Flex flexDir="column" gridGap={8}>
          <Heading textAlign="left" as="h3" size="lg" noOfLines={1}>
            Desafios Ativos
          </Heading>
          <SimpleGrid
            gap="20px"
            columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
          >
            {challengesList.map((challenge, index) => (
              <VideoCard key={index} video={challenge.video} />
            ))}
          </SimpleGrid>
        </Flex>

        <Flex flexDir="column" gridGap={8} mt={20}>
          <Heading textAlign="left" as="h3" size="lg" noOfLines={1}>
            Desafios Expirados
          </Heading>
          <SimpleGrid
            gap="20px"
            columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }}
          >
            {/*videos.map((videoData) => (
              <VideoCard video={videoData} />
            ))*/}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}
