import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Text,
  useDisclosure,
  useStyleConfig,
  ListIcon,
  IconButton,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useRouteMatch, NavLink } from "react-router-dom";
import { PageHeading } from "../../components/PageHeading";
import VideoPickerDrawer from "../../components/VideoPickerDrawer";
import {
  addTraining,
  DailyExercises,
} from "../../dal/supabase/models/TrainingModel";
import {
  GetVideosFromUser,
  VideoModelInterface,
} from "../../dal/supabase/models/VideoModel";
import { supabase } from "../../supabaseClient";
import DailyTraining from "./DailyTraining";
import { Store } from "react-notifications-component";
import DraggableList from "react-draggable-list";
import { FiList } from "react-icons/fi";

interface DraggableDailyExercises extends DailyExercises {
  index: number;
  isExpanded?: boolean;
}

export default function AddTraining() {
  const [filterInput, setFilterInput] = useState("");
  const [trainingList, setTrainingList] = useState<
    Array<DraggableDailyExercises>
  >([]);
  const [currentTrainingDay, setCurrentTrainingDay] =
    useState<DailyExercises>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [videosList, setVideosList] = useState<Array<VideoModelInterface>>([]);

  const cardsStyle = useStyleConfig("CustomCard");

  let match = useRouteMatch();

  // Update the state when input changes
  const handleFilterChange = (e: any) => {
    const value = e.target.value || undefined;
    console.log("pesquisando");
    setFilterInput(value);
  };

  const Template = ({
    item,
    itemSelected,
    dragHandleProps,
  }: {
    item: DraggableDailyExercises;
  }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;

    return (
      <DailyTraining
        dragHandleProps={dragHandleProps}
        key={item.index}
        day={item.day}
        videosList={item.exercises}
        isExpanded={item.isExpanded}
        onAddClick={() => {
          setCurrentTrainingDay(item);
          onOpen();
        }}
        onRemoveVideoClick={(videoIndex) => {
          console.log("item day = " + item.day);
          console.log("current day = " + currentTrainingDay?.day);
          console.log("removing video from index " + videoIndex);

          if (item !== undefined) {
            item.exercises = item?.exercises.filter(
              (_, idx) => idx !== videoIndex
            );
            console.log("deleted");
          }
        }}
        onRemoveDayClick={() =>
          setTrainingList(
            trainingList
              .filter((training, idx) => training.day !== item.day)
              .map((training, idx) => {
                training.day = idx + 1;
                training.index = idx;
                return training;
              })
          )
        }
        onChange={(isExpanded) => (item.isExpanded = isExpanded)}
      />
    );
  };

  async function addNewTraining(values: any, actions: any) {
    console.log(values);

    if (supabase.auth.user() === null) {
      Store.addNotification({
        title: "Erro ao adicionar vídeo!",
        message: `Não logado. Favor logar`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      actions.setSubmitting(false);

      return;
    }

    const { error } = await addTraining({
      training: {
        title: values.title,
        description: values.description,
        user_id: supabase.auth.user()?.id || "",
        days: trainingList.length,
      },
      dailyExercises: trainingList,
    });

    console.log(error);
    if (error) {
      Store.addNotification({
        title: "Erro ao adicionar treino!",
        message: `${error.message}. Código ${error.code}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      Store.addNotification({
        title: "Treino adicionado!",
        message: "Seu treino foi adicionado com sucesso",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }

    actions.setSubmitting(false);
  }

  useEffect(() => {
    async function getAllVideos() {
      let videosAll = await GetVideosFromUser();
      setVideosList(videosAll.data);
    }

    getAllVideos();
  }, []);

  return (
    <Stack spacing={3}>
      <VideoPickerDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        videosList={videosList}
        onSelect={(video) => {
          console.log(video);
          console.log(currentTrainingDay);
          currentTrainingDay?.exercises.push(video);
          onClose();
        }}
      />
      <Flex>
        <Flex w="full" flexDir="column">
          <Flex flexDir="row" justifyContent="space-between">
            <PageHeading
              title="Adicionar Treino"
              subtitle="Crie seu treino abaixo"
            />
          </Flex>
          <Formik
            initialValues={{
              title: "",
              description: "",
              video: undefined,
            }}
            onSubmit={(values, actions) => {
              /*setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 1000);*/

              console.log(trainingList);
              addNewTraining(values, actions);
              //addNewChallenge(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="title">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.title && form.touched.title}
                    >
                      <FormLabel htmlFor="title">Título do treino</FormLabel>
                      <Input
                        {...field}
                        sx={cardsStyle}
                        id="challengeTitle"
                        placeholder="Título do treino"
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="description">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                    >
                      <FormLabel htmlFor="description">
                        Descrição do treino
                      </FormLabel>
                      <Textarea
                        sx={cardsStyle}
                        {...field}
                        id="challengeDescription"
                        placeholder="Insira a descrição do treino"
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="days">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                    >
                      <FormLabel htmlFor="days">Total de dias</FormLabel>
                      <Input
                        sx={cardsStyle}
                        {...field}
                        id="days"
                        isDisabled
                        value={trainingList.length}
                      />
                    </FormControl>
                  )}
                </Field>
                <Flex mt={8} flexDir="column" gridGap={8}>
                  <DraggableList
                    itemKey={"index"}
                    template={Template}
                    container={() => document.body}
                    list={
                      trainingList /*trainingList.map((training, idx) => (
                    {idx: idx, training:training}
                  ))*/
                    }
                    onMoveEnd={(newList, movedItem, oldIndex, newIndex) => {
                      // store the new List
                      console.log("newList", newList);
                      console.log("moved item:", movedItem);
                      console.log("oldIndex", oldIndex);
                      console.log("newIndex", newIndex);

                      var newTrainingList = (
                        newList as DraggableDailyExercises[]
                      ).map((item, idx) => {
                        item.day = idx + 1;
                        return item;
                      });

                      console.log(newTrainingList);
                      setTrainingList(newTrainingList);
                      console.log("training setted");
                    }}
                  />
                  <Button
                    leftIcon={<MdAdd />}
                    //colorScheme="gray"
                    onClick={() =>
                      setTrainingList([
                        ...trainingList,
                        {
                          day: trainingList.length + 1,
                          exercises: [],
                          index: trainingList.length,
                        },
                      ])
                    }
                  >
                    Adicionar Dia
                  </Button>
                </Flex>
                <Flex mt={16} gridGap={4} justifyContent="flex-end">
                  <Button as={NavLink} to="training">
                    Cancelar
                  </Button>
                  <Button
                    bg="brand.primary"
                    color="white"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Cadastrar Treino
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Stack>
  );
}
